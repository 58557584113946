import React from 'react';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import copyIcon from 'assets/Images/copyIcon.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { formatDate } from 'utils/formatDate';

// Interface for the props used in PaymentDetail component
interface PaymentDetailProps {
  status: string;
  amount: string;
  quote: string;
  // transcID: string;
  insuredName: string;
  paymentDate: string;
  icon: any;
  policyNumber?: string;
  serviceFee?: string;
  baseAmount?: string;
}

// PaymentDetail Functional Component
const PaymentDetail: React.FC<PaymentDetailProps> = ({
  status,
  amount,
  quote,
  insuredName,
  paymentDate,
  icon,
  policyNumber,
  serviceFee,
  baseAmount,
}) => {
  const navigate = useNavigate();

  // Function to handle the click event, redirecting to the select-method route
  const handleClick = () => {
    navigate('/bills');
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}` || '';
  };

  // const userDetail = [
  //   { key: 'Insured Name', value: insuredName },
  //   { key: 'Base Amount', value: baseAmount },
  //   { key: 'Service Fee', value: serviceFee },
  //   { key: 'Payment Date', value: formatDate(paymentDate) },
  // ];

  // Component rendering payment details
  return (
    <div>
      {/* Displaying payment status, amount and additional message */}
      <div className="p-4 rounded-lg border-gray-100 border-solid">
        <div className="flex flex-col justify-center items-center gap-2">
          <img src={icon} alt="Payment status icon" />
          <Typography fontWeight={600} fontSize={24}>
            {status}
          </Typography>
          <Typography fontWeight={600} fontSize={36}>
            ${amount}
          </Typography>
          <Typography
            textAlign={'center'}
            width={427}
            fontWeight={400}
            fontSize={14}
            color={'gray'}
          >
            {quote}
          </Typography>
        </div>

        {/* Displaying transaction details */}
        <div className="bg-gray-100 rounded-lg p-4 mt-6">
          {/* {userDetail.map((el) => (
            <>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <Typography fontWeight={500} fontSize={14}>
                    {el.key}
                  </Typography>
                  {el.key === "Transaction ID" && (
                    <img className="cursor-pointer" src={copyIcon} alt="Copy Icon" />
                  )}
                </div>

                <Typography fontWeight={400} fontSize={14} color={"gray"}>
                  {el.value}
                </Typography>
              </div>
              {el.key !== "Payment Date" && <Divider className="w-full my-4" />}
            </>
          ))} */}

          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <Typography fontWeight={500} fontSize={14}>
                Insured Name
              </Typography>
            </div>

            <Typography fontWeight={400} fontSize={14} color={'gray'}>
              {insuredName}
            </Typography>
          </div>
          <Divider className="w-full my-4" />
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <Typography fontWeight={500} fontSize={14}>
                  Base Amount
                </Typography>
                {/* {el.key === "Transaction ID" && (
                    <img className="cursor-pointer" src={copyIcon} alt="Copy Icon" />
                  )} */}
              </div>

              <Typography fontWeight={400} fontSize={14} color={'gray'}>
                {baseAmount}
              </Typography>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <Typography fontWeight={500} fontSize={14}>
                  Service Fee
                </Typography>
              </div>

              <Typography
                fontWeight={400}
                fontSize={14}
                color={'gray'}
                className="text-right"
              >
                {serviceFee}
              </Typography>
            </div>
          </div>

          <Divider className="w-full my-4" />
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <Typography fontWeight={500} fontSize={14}>
                Payment Date
              </Typography>
              {/* {el.key === "Transaction ID" && (
                    <img className="cursor-pointer" src={copyIcon} alt="Copy Icon" />
                  )} */}
            </div>

            <Typography fontWeight={400} fontSize={14} color={'gray'}>
              {formatDate(paymentDate)}
            </Typography>
          </div>
          {/* <div className="flex flex-col gap-2">
            <Typography fontWeight={500} fontSize={14}>
              Transaction ID
            </Typography>
            <div className="flex justify-between">
              <Typography fontWeight={400} fontSize={14} color={"gray"}>
                {transcID}
              </Typography>
              <img src={copyIcon} alt="Copy Icon" />
            </div>
          </div> */}
          {/* <Divider className="w-80 my-4" /> */}
          {/* <div className="flex flex-col gap-2">
            <Typography fontWeight={500} fontSize={14}>
              Payment Method
            </Typography>
            <Typography fontWeight={400} fontSize={14} color={"gray"}>
              {bankName}
            </Typography>
            <Typography fontWeight={400} fontSize={14} color={"gray"}>
              {cardNumber}
            </Typography>
          </div> */}
        </div>

        {/* Conditionally rendering policy number for successful payments */}
        {status === 'Payment Successful' && (
          <div className="bg-gray-100 rounded-lg p-4 mt-4">
            <Typography fontWeight={600} fontSize={14} color={'green'}>
              Payment process successful
            </Typography>
            <Typography fontWeight={500} fontSize={14}>
              Your Policy Number : {policyNumber}
            </Typography>
          </div>
        )}
      </div>

      {/* Navigation buttons */}

      <div className="w-full flex justify-between mt-4 items-center">
        <Button
          variant="text"
          className="h-12 text-md mt-4"
          onClick={handleClick}
        >
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          Back
        </Button>
        {status === 'Payment Denied' && (
          <Button
            className="w-56 h-11 bg-black text-white hover:text-black"
            onClick={handleClick}
          >
            Try Again
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaymentDetail;
