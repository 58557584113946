import { Grid, Slide ,Typography} from "@mui/material";
import React, { useEffect } from "react";

import LoginSignupForm from "./LoginSignupForm";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/RouteConstants";
import Slider from "./SliderLoginPage";
import Branding from "components/Branding";

// AuthPage component is responsible for rendering the authentication page,
// which includes the login/signup form and the slider.
const AuthPage = () => {
 const navigate = useNavigate()
 
  useEffect(() => {
    if(Boolean(localStorage.getItem('AUTH_TOKEN'))){
      navigate("/dashboard")
    }
  }, [])

  return (
    <>
      {/* Grid container to structure the layout of the page */}
      <Grid container className="m-auto w-full h-screen">
        {/* Grid item for the login/signup form. It takes up half of the width on medium devices and up. */}
        <Grid
          item
          xs={12}
          md={6}
          className="flex flex-col justify-center items-center relative py-11 md:py-0"
        >
          {/* Component containing the login and signup form */}
          <LoginSignupForm />
          {/* <div className="md:absolute md:block hidden relative  md:bottom-0 md:left-1/2 md:-translate-x-1/2 py-4">
            <Branding />
          </div> */}
        </Grid>

        {/* Grid item for the slider. It also takes up half of the width on medium devices and up. */}
        <Grid item xs={12} md={6} className="w-full">
          {/* <Slide direction="left" in timeout={600}>
            <div className="loginImage h-screen w-[100%]"></div>
          </Slide> */}
          <Slider />
        </Grid>
        <div className=" relative md:hidden   left-1/2 -translate-x-1/2 py-5">
        <Branding />
                <Typography
                    variant="subtitle1"
                    color="#757575"
                    className="mb-4 ml-5"
                  >
                    © Farm Bureau
                  </Typography>
           
          </div>
      </Grid>
    </>
  );
};

export default AuthPage;
