import AddCardForm from "./AddCardForm";

// Breadcrumbs for navigation
const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "Debit Card/Credit Card > Payment Methods", href: "/add-payment-method-card" },
];

const PaymentMethodCard = () => {
  return <AddCardForm breadcrumbItems={breadcrumbItems}/>
};

export default PaymentMethodCard;
