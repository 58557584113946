import React, { useEffect, useMemo, useState } from "react";
import ShowBreadCrumb from "./BreadCrumb";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Breadcrumbs } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FetchBillsAPI,
  FetchNextBillAPI,
  useDeleteFundingAccountQuery,
  useFundingAccountsQuery,
  useMarkPrimaryFundingAccount,
} from "queries/BillsQueries";
import ConfirmModal from "components/Common/ConfirmModal";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&::before": {
    background: "none",
  },
}));

const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "Payment Methods", href: "/manage-payment-method" },
];

const allCardData = [
  { id: 1, cardName: "Debit Card/Credit Card" },
  { id: 2, cardName: "ACH" },
];

const ManagePaymentMethods = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userEnteredAmount } = location.state || {};
  const [actionInfo, setActionInfo] = useState<any>(null);
  const { mutate: deleteCard } = useDeleteFundingAccountQuery();
  const { mutate: markPrimaryCard } = useMarkPrimaryFundingAccount();
  const { data: receivedBills } = FetchBillsAPI(1, 50, "ASC", false);
  const { data: bill } = FetchNextBillAPI();
  const { amountDue, policyNo, invoiceId } = bill?.data || {};
  const {
    getBillFromLocation,
    invoiceId: billInvoiceId,
    policyNo: billPolicyNo,
    amountDue: billAmountDue,
  } = location.state?.billData || {};

  const getPolicyNumber = () => {
    if (getBillFromLocation) {
      return billPolicyNo;
    }
    return policyNo || receivedBills?.data?.[0]?.policyNo || "policy No";
  };

  const { data: fundingAccountsCardData } = useFundingAccountsQuery({
    page: 1,
    pageSize: 50,
    sort: "DESC",
    billerAccountId: getPolicyNumber(),
  });
  const fundingAccountCards = fundingAccountsCardData?.data;

  const navigateToPrevScreen = () => {
    navigate("/bills");
  };

  const handlePaymentModeClick = (mode: any) => {
    mode == 1
      ? navigate("/add-payment-method-card", {
          state: { userEnteredAmount },
        })
      : navigate("/add-payment-method-ach", {
          state: { userEnteredAmount },
        });
  };

  const handleSetActionInfo = (
    cardNumber: Number,
    cardName: string,
    fundingToken: string,
    action: string
  ) => {
    setActionInfo({ cardNumber, cardName, fundingToken, action });
  };

  const handleAction = () => {
    if (actionInfo?.action === "delete") {
      deleteCard(
        {
          billerAccountId: getPolicyNumber(),
          billerId: process.env.REACT_APP_SPEED_PAY_BILLER_ID,
          fundingAccountId: actionInfo?.fundingToken,
        },
        {
          onSuccess: () => {
            setActionInfo(null);
          },
        }
      );
    } else {
      markPrimaryCard(
        {
          fundingAccountId: actionInfo?.fundingToken,
        },
        {
          onSuccess: () => {
            setActionInfo(null);
          },
        }
      );
    }
  };

  return (
    <div className="!w-[530px]">
      <Grid item xs={12}>
        <Breadcrumbs className="mt-4" aria-label="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className={index === breadcrumbItems.length - 1 ? "lastLink" : ""}
              style={
                index === breadcrumbItems.length - 1
                  ? { outline: "none", color: "#BB3330", fontWeight: "600" }
                  : {
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }
              }
            >
              {item.label}
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Select Payment Method
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {fundingAccountCards?.map((card: any) => {
          return card?.paymentMethodKind === "Ach" ? (
            <PaymentCard
              isCard={false}
              isPrimary={card?.isPrimary}
              bankName={card?.accountName}
              cardNumber={card?.truncatedDdaNo}
              handleSetActionInfo={handleSetActionInfo}
              fundingToken={card?.fundingToken}
            />
          ) : (
            <PaymentCard
              isCard={true}
              isPrimary={card?.isPrimary}
              bankName={card?.accountName}
              cardNumber={card?.truncatedCardNo}
              handleSetActionInfo={handleSetActionInfo}
              fundingToken={card?.fundingToken}
            />
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ border: "1px solid #EDEDED" }} className="mt-4">
          {allCardData?.map((info, index) => {
            const { id, cardName } = info;
            return (
              <div
                tabIndex={0}
                onClick={() => handlePaymentModeClick(id)}
                className="cursor-pointer"
              >
                <CardContent
                  sx={{ borderBottom: index === 0 ? "1px solid #EDEDED" : "" }}
                >
                  <Box className="flex justify-between">
                    <Box className="flex items-center gap-4">
                      {id === 2 ? <AccountBalanceIcon /> : <CreditCardIcon />}
                      {cardName}
                    </Box>
                    <ChevronRightIcon />
                  </Box>
                </CardContent>
              </div>
            );
          })}
        </Card>
      </Grid>
      <ConfirmModal
        handleClose={() => setActionInfo(null)}
        open={actionInfo !== null}
        message={
          actionInfo?.action === "delete" ? "Delete Card" : "Make Primary"
        }
        buttonSize="small"
        continueEdit={handleAction}
      >
        <Box className="mb-4">
          {actionInfo?.action === "delete" ? (
            <span className="text-sm">
              Are you sure you want to delete <b>{actionInfo?.cardName}</b>
            </span>
          ) : (
            <span className="text-sm">
              {" "}
              Are you sure you want to make <b>{actionInfo?.cardName}</b>
              as your primary.
            </span>
          )}
        </Box>
      </ConfirmModal>
    </div>
  );
};

export default ManagePaymentMethods;

const PaymentCard = ({
  isPrimary,
  bankName,
  cardNumber,
  isCard,
  handleSetActionInfo,
  fundingToken,
}: any) => {
  const modeIcons = (
    <Box className="w-10 flex items-center">
      {isCard ? <CreditCardIcon /> : <AccountBalanceIcon />}
    </Box>
  );

  const cardInfoBox = (
    <Box>
      <Typography variant="subtitle1" className="text-sm font-bold">
        {bankName}
      </Typography>
      <Typography variant="subtitle1" className="text-sm font-bold">
        **** **** **** {cardNumber}
      </Typography>
    </Box>
  );

  return isPrimary ? (
    <Card
      className="flex flex-row mt-4"
      style={{
        border: "1px solid #EDEDED",
        height: "78px",
        borderRadius: "0px",
        boxShadow: "none",
      }}
    >
      <CardContent className="w-full flex">
        {modeIcons}
        <Box className="flex justify-between w-[calc(100%-40px)]">
          {cardInfoBox}
          <Box className="relative">
            <Typography
              variant="subtitle1"
              className="text-sm font-semibold text-[#095F3C] absolute right-0 top-6"
            >
              Primary
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : (
    <StyledAccordion
      sx={{ boxShadow: "none", border: "1px solid #EDEDED" }}
      className="mt-4"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {modeIcons}
        {cardInfoBox}
      </AccordionSummary>
      <AccordionDetails
        sx={{ borderTop: "1px solid #EDEDED", padding: "0px" }}
        className="flex justify-between"
      >
        <Button
          className="w-1/2 text-sm"
          sx={{
            border: "0px",
            borderRight: "1px solid #EDEDED",
            outline: "none",
            borderRadius: "0px",
            "&:hover": {
              border: "0px",
              borderRight: "1px solid #EDEDED",
              background: "none",
            },
          }}
          onClick={() =>
            handleSetActionInfo(cardNumber, bankName, fundingToken, "primary")
          }
        >
          Make Primary
        </Button>
        <Button
          className="w-1/2 text-sm"
          sx={{
            border: "0px",
            outline: "0px",
            borderRadius: "0px",
            "&:hover": { border: "0px", background: "none" },
          }}
          onClick={() =>
            handleSetActionInfo(cardNumber, bankName, fundingToken, "delete")
          }
        >
          Delete
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  );
};
