import AddCardForm from "./AddCardForm";


// Breadcrumbs for navigation
const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "Add New Card", href: "/add-card" },
];

const AddCard = () => {
  return (
    <AddCardForm breadcrumbItems={breadcrumbItems}/>
  );
};

export default AddCard;
