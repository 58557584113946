import ACHdirectDebitForm from "./ACHdirectDebitForm";

const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "ACH > Payment Methods", href: "/add-payment-method-ach" },
];

const PaymentMethodACH = () => {
  return <ACHdirectDebitForm breadcrumbItems={breadcrumbItems} />;
};
export default PaymentMethodACH;
