import axios from "services/axiosInstance";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getLocalStorageItem } from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import HandleErrorResponse from "helper/HandleErrorResponse";
import { IFetchPolicyDocDetailResponse } from "./types";

// Function to add an existing policy via a POST request
export const AddExistingPolicy = (data: any) => {
  console.log("Data:", data);
  const method = "POST";
  const url = `/v1/policy/add-existing-policy`;
  return axios({ method, url, data });
};

// Custom type for error handling
type MyError = {
  message: string
};

// React Query hook to add an existing policy
export const AddExistingPolicyAPI = () => {
  const queryClient = useQueryClient();
  return useMutation(AddExistingPolicy, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries(["GET_AGENT"]);
      queryClient.invalidateQueries(["GET_POLICY_LIST"]);
      queryClient.invalidateQueries(["GET_POLICY_BILLING_DOC_DETAIL"]);
      queryClient.invalidateQueries("CHECK_EXISTING_POLICY");
      queryClient.invalidateQueries(["GET_NEXT_BILL"])
      console.log(res, "Error:toastMsg", "success");
    },
    onError: (err: MyError) => {
      // Handle error response
      // HandleErrorResponse("Invalid Policy");
    },
  });
};


export const FetchPolicyServeSync = (path:string) => {
  const pathurl = encodeURIComponent(path)
  const method = "GET";
  const url = `/v1/policy/serve?path=${pathurl}`;
  return axios({ method, url, headers: {path: pathurl}, responseType: 'blob' });
};

// React Query hook to fetch policies
export const FetchPolicyServeSyncAPI = (path:string, enabled = true) => {
  console.log({path})
  return useQuery<any>(
    ["GET_POLICY_SERVE_SYNC", path],
    () => FetchPolicyServeSync(path),
    {
      onSuccess: (res) => {
        // const fileName = path.substring(path.lastIndexOf('/') + 1);
        // const link = document.createElement('a');
        // const blob = new Blob([res], { type: 'application/pdf' });
        // link.href = window.URL.createObjectURL(blob);
        // link.download = fileName;
        // link.click();
        // window.URL.revokeObjectURL(link.href);

        
        const blob = new Blob([res], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
        // link.href = window.URL.createObjectURL(blob);
        // link.download = fileName;
        // link.click();

      },
      onError: (error) => {
        console.log("Error:", error);
      },
      enabled
    }
  );
};








// Function to fetch all policies via a GET request
export const FetchPolicySync = () => {
  const method = "GET";
  const url = `/v1/policy/sync`;
  return axios({ method, url });
};

// React Query hook to fetch policies
export const FetchPolicySyncAPI = () => {
 

  return useQuery(["GET_POLICY_SYNC"], FetchPolicySync, {
   
    onSuccess: (res) => {
      
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};





// Function to fetch all policies via a GET request
export const FetchPolicies = () => {
  const method = "GET";
  const url = `/v1/policy`;
  return axios({ method, url });
};

// React Query hook to fetch policies
export const FetchPoliciesAPI = () => {
  const queryClient = useQueryClient();

  return useQuery(["GET_POLICY_LIST"], FetchPolicies, {
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["GET_POLICY_SYNC","GET_LOGGED_IN_USER_DETAILS","GET_AGENT"]);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch details of a specific policy via a GET request
export const FetchPolicyDetail = (policyNumber: any, type: string) => {
  const method = "GET";
  const url = `/v1/policy/${policyNumber}?type=${type}`;
  return axios({ method, url });
};

// React Query hook to fetch specific policy details
export const FetchPolicyDetailAPI = (policyNumber: any, policyType: any) => {
  return useQuery(
    ["GET_POLICY_DETAIL", policyNumber, policyType],
    () => FetchPolicyDetail(policyNumber, policyType),
    {
      onSuccess: (res) => {
        console.log("PolicyDetail:", res);
      },
      onError: (error) => {
        console.log("Error:", error);
      },
    }
  );
};

// Function to fetch policy document details
export const FetchPolicyDocDetail = (params: {policyNo: string, type: string, docType: string, page?: number, pageSize?: number}): Promise<IFetchPolicyDocDetailResponse> => {
  const method = "GET";
  const url = `/v1/policy/docs`;
  return axios({ method, url, params });
};

// React Query hook to fetch policy document details
export const FetchPolicyDocDetailAPI = (policyNo: string, type: string, docType: string, page?: number, pageSize?: number) => {
  return useQuery<IFetchPolicyDocDetailResponse>(
    ["GET_POLICY_DOC_DETAIL", policyNo, type, docType, page, pageSize],
    () => FetchPolicyDocDetail({ policyNo, type, docType, page, pageSize }),
    {
      onSuccess: (res) => {
        console.log("PolicyDetail:", res);
      },
      onError: (error) => {
        console.log("Error:", error);
      },
    }
  );
};

// Function to check if user has an existing policy
export const CheckUserPolicyExists = () => {
  const method = "GET";
  const url = `/v1/policy/check-user-policy-exists`;
  return axios({ method, url });
};

// React Query hook to check if user has an existing policy
export const CheckUserPolicyExistsAPI = () => {
  return useQuery("CHECK_EXISTING_POLICY", CheckUserPolicyExists, {
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch policy agent details
export const FetchPolicyAgent = () => {
  const method = "GET";
  const url = `/v1/policy/agent`;
  return axios({ method, url });
};

// React Query hook to fetch policy agent details
export const FetchPolicyAgentAPI = () => {
  return useQuery("GET_AGENT", FetchPolicyAgent, {
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to delete a specific policy
export const deletePolicy = async (policyNum: string) => {
  const method = 'DELETE';
  const url = `/v1/policy/${policyNum}/remove`;
  return await axios({ method, url });
};

// React Query hook to delete a specific policy
export const useDeletePolicyApi = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePolicy, {
    onSuccess: () => {
      queryClient.invalidateQueries(['GET_POLICY_LIST']);
      queryClient.invalidateQueries("CHECK_EXISTING_POLICY");
      queryClient.invalidateQueries(["GET_NEXT_BILL"])
    },
  });
};


 // Function to update paperless enrolled via a PATCH request
 const updatePaperlessEnrolled = (payload: any) => {
  const { policyId, paperlessEnrolled } = payload || {};
  const data = {
    paperlessEnrolled
  }
  const method = "PATCH";
  const url = `/v1/policy/${policyId}/paperless-enrolled`;
  return axios({ method, url, data });
};

// React Query hook for updating paperless enrolled
export const UseUpdatePaperlessEnrolledAPI = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePaperlessEnrolled, {
    onSuccess: (response) => {
      console.log({ response });
      queryClient.invalidateQueries("GET_POLICY_LIST");
    },
  });
};


// Function to fetch policy document details
export const FetchPolicyBillingDocDetail = ()=> {
  const method = "GET";
  const url = `/v1/policy/billing-docs`;
  return axios({ method, url });
};

// React Query hook to fetch policy document details
export const FetchPolicyBillingDetailAPI = () => {
  return useQuery(
    ["GET_POLICY_BILLING_DOC_DETAIL"],
    () => FetchPolicyBillingDocDetail(),
    {
      onSuccess: (res) => {
        console.log("PolicyBillingDetail:", res);
      },
      onError: (error) => {
        console.log("Error:", error);
      },
    }
  );
};


export const FetchPolicyProgress = () => {
  const method = "GET";
  const url = `/v1/policy/progress`;
  return axios({ method, url });
};

export const useFetchPolicyProgress = () => {
  return useQuery(["GET_POLICY_PROGRESS"], () => FetchPolicyProgress(), {
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
  });
};