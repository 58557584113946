import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "./index.css";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { FetchBillsAPI, FetchNextBillAPI, GetSyncPolicyDocs } from "queries/BillsQueries";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowDropDownIcon from "assets/Icons/arrow-down.svg";
import {
  CheckUserPolicyExistsAPI,
  FetchPolicyBillingDetailAPI,
  FetchPolicyServeSyncAPI,
  useFetchPolicyProgress,
} from "queries/PolicyQuery";
import LabelledTextField from "components/Common/LabelledTextField";
import InputItem from "pages/Claims/Collision/InputItem";
import { useEffect, useState } from "react";
import { FetchUserDetailsAPI } from "queries/AuthQueries";
import HandleErrorToast from "helper/HandleErrorResponse";

// Function to get the ordinal suffix for a day
function getDaySuffix(day: any) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// Function to format a date string into a readable format with ordinal day suffix

function formatDate(dueDateStr: string) {
  const date = new Date(dueDateStr);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month as it is zero-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

// Main component for Bills
const Bills = () => {
  const { data: userDetails, dataUpdatedAt } = FetchUserDetailsAPI();
  const { data: bill } = FetchNextBillAPI();
  const { data: syncPolicyDocs } = GetSyncPolicyDocs(userDetails?.data?.id);
  const {data : userPolicyDetails } : any = CheckUserPolicyExistsAPI();
  const { data: receivedBills } = FetchBillsAPI(1, 50, "ASC", false);
  const navigate = useNavigate();
  const nextBill = bill?.data;
  const [userEnteredAmount, setUserEnteredAmount] = useState(0);

  const [pathName, setPathName] = useState("");

  const { data: policyServeDetail, refetch: refetchPolicyServe } =
    FetchPolicyServeSyncAPI(pathName, false);

  const handleAmountChange = (amount: any) => {
   if(amount.includes("-"))
    amount = amount.split("-")[1]
    setUserEnteredAmount(amount);
  };
  // const defaultBill=1;

  const { data: billingData } = FetchPolicyBillingDetailAPI();
  useFetchPolicyProgress();

  const billingDocData = billingData?.data;

  console.log(billingDocData, "billingDocData", billingData);

  // Function to navigate to the payment screen
  const navigateToNextScreen = (path : string) => {
    navigate(path, {
      state: { userEnteredAmount },
    });
    // navigate("/add-card")
  };

  useEffect(() => {
    if (pathName) {
      refetchPolicyServe();
    }
  }, [refetchPolicyServe, pathName]);

  const handleDownload = (url: string) => {
    // const encodedUrl = encodeURIComponent(url);

    setPathName(url);

  };

  useEffect (() => {
    if(nextBill?.amountDue){
      setUserEnteredAmount(nextBill?.amountDue)
    }
  },[nextBill])

  return (
    <div className="w-[98%] h-screen overflow-scroll no-scrollbar">
      <Grid container className="mt-1" spacing={2}>
        {/* Next Bill Section */}
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="mt-4 ml-2 font-semibold text-lg leading-8"
          >
            Next Bill
          </Typography>
        </Grid>
        <Card className="w-full m-2 ml-2">
          <CardContent>
            <Grid item xs={12}>
              {nextBill?.amountDue ? (
                <Grid container>
                  {/* Bill Amount */}
                  <Grid item xs={12} sm={9}>
                    <Typography
                      variant="subtitle1"
                      color="#0F0F0F"
                      className="ml-2"
                    >
                      <span className="font-semibold text-[36px] leading-[52px]">
                        ${nextBill?.amountDue}
                      </span>
                    </Typography>
                  </Grid>
                  {/* Due Date */}
                  {nextBill?.dueDate && (
                    <Grid item xs={12} sm={3}>
                      <Typography
                        color="#757575"
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        className="mt-2"
                      >
                        Due
                      </Typography>
                      <Typography
                        color="#262626"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                      >
                        {nextBill?.dueDate && (
                          <span>
                            {new Date(nextBill?.dueDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </span>
                        )}
                        {/* Dec 12, 2023 */}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Enter Amount"
                  value={userEnteredAmount}
                  onChange={(e) => handleAmountChange(e.target.value)}
                />
              )}
            </Grid>
            {/* Make Payment Button */}
            <Grid item xs={12} className="mt-4">
              <Button
                color="secondary"
                fullWidth
                type="submit"
                size="large"
                className=" w-full items-center justify-center m-auto flex h-12 custom-button"
                disabled={!nextBill?.amountDue && (!userEnteredAmount || userEnteredAmount <= 0 )}
                onClick={() => {
                  if(userPolicyDetails && userPolicyDetails?.data?.policyExists === false){
                    HandleErrorToast({message: "No policy found!"});
                  }
                  else {
                    navigateToNextScreen("/select-payment-method")
                  }
                }}
              >
                <span className="text-base font-medium text-sm">
                  Make a Payment
                </span>
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Payment Received Section */}
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="mt-4 ml-2 font-semibold text-lg"
          >
            Payment Received
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {/* Mapping through received bills and displaying them */}
        {receivedBills?.data?.map((payment: any, index: number) => (
          <Grid item xs={12} key={payment.id}>
            <Card
              className="flex flex-row"
              style={{
                borderBottom: "1px solid #EDEDED",
                height: "78px",
                borderRadius: "0px",
                boxShadow: "none",
              }}
            >
              <CardContent className="w-full">
                <Grid container>
                  <Grid item xs={12} direction="column">
                    <Typography
                      variant="h6"
                      color="#333333"
                      fontWeight={500}
                      className="text-start text-[16px] leading-[28px]"
                    >
                      ${payment.amountDue}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      color="#757575"
                      className="text-[12px] font-semibold leading-[16px] mt-1"
                    >
                      {new Date(payment.dueDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Button
          className="bg-white border border-gray-700 !shadow-[rgba(15,15,15,0.06)_0px_2px_8px_0px] rounded-md"
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          onClick={() => navigateToNextScreen("/manage-payment-method")}
        >
          <CreditCardIcon className="mr-1" />
          <span className="text-base font-medium text-sm">Payment Methods</span>
        </Button>
      </Grid>
      {billingDocData?.length ? (
        <Grid
          container
          alignItems="stretch"
          className="w-[calc(100%-4px)] mt-[12px]"
        >
          <Grid item xs={12}>
            <Grid container direction="row" className="p-2">
              <Typography
                variant="subtitle1"
                color="#545454"
                className="font-semibold text-base"
              >
                Billing Documents
              </Typography>
              <div
                style={{ backgroundColor: "#F5F5F5", borderRadius: "4px" }}
                className="h-[24px] w-[23px] flex justify-center mb-1 ml-2"
              >
                <Typography
                  color="#545454"
                  className="flex flex-row items-center font-semibold text-xs leading-4"
                >
                  {billingDocData.length}
                </Typography>
              </div>
            </Grid>

            <Grid className="flex flex-col w-full justify-center gap-2">
              {billingDocData.map(
                ({ documentId, url, uploadedDate, documentName, documentType }: any) => (
                  <Grid item key={documentId}>
                    <Card className="p-6 shadow-[rgba(15,15,15,0.08)_0px_4px_12px_0px]">
                      <div className="flex items-center justify-between">
                        <div className="flex-1">
                          {/* <Tooltip title={documentId}>
                              <Typography
                                color="#333333"
                                className="font-semibold text-base leading-5 overflow-hidden whitespace-nowrap overflow-ellipsis"
                                style={{ maxWidth: "135px" }}
                              >
                                {documentName}
                              </Typography>
                            </Tooltip> */}
                          <Typography
                            color="#333333"
                            className="font-semibold text-base leading-5 overflow-hidden whitespace-nowrap overflow-ellipsis"
                          >
                            {documentName}
                          </Typography>
                        </div>

                        <Button
                          className="ml-2 w-[100px] h-[32px] bg-white border border-gray-700 !shadow-[rgba(15,15,15,0.06)_0px_2px_8px_0px] rounded-md"
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDownload(`${url}?documentType=${documentType}`)}
                        >
                          <img
                            src={ArrowDropDownIcon}
                            alt=""
                            style={{ paddingRight: "5px" }}
                          />
                          <span className="font-normal text-xs leading-4">
                            Download
                          </span>
                        </Button>
                      </div>
                      <Typography
                        color="#757575"
                        className="font-normal text-xs leading-5 "
                      >
                        Uploaded: {formatDate(uploadedDate)}
                      </Typography>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};
export default Bills;