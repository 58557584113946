import ACHdirectDebitForm from "./ACHdirectDebitForm";

const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "Bank Details", href: "/direct-debit" },
];

const DirectDebit = () => {
  return <ACHdirectDebitForm breadcrumbItems={breadcrumbItems} />;
};
export default DirectDebit;
