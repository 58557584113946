/* eslint-disable import/no-extraneous-dependencies */
import * as yup from "yup";

// Form validation schemas using Yup

export const FormValidationSchemas = {
  password: yup
    .string()
    .nullable()
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password should not exceed 32 characters")
    .matches(/([a-z])/, {
      message: "Atleast one small letter",
      name: "small letter",
      excludeEmptyString: true,
    })
    .matches(/[a-z]/, "Atleast one small letter")
    .matches(/[A-Z]/, "Atleast one capital letter")
    .matches(/(\d)/, "Atleast one number")
    // .matches(/(\W)/, "Atleast one special character")
    .required("Password is required"),

  confirm_password: yup
    .string()
    .nullable()
    .required("Confirm password is required")
    // eslint-disable-next-line consistent-return
    .when("password", (password, schema): any => {
      if (password)
        return schema
          .required("Required")
          .test(
            "Match password",
            "Password does not match",
            (confirmPasswordValue: string | null, formValues: any) => {
              return confirmPasswordValue === formValues.parent.password;
            }
          );
    }),

  old_password: yup
    .string()
    .nullable()
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password should not exceed 32 characters")
    .matches(/([a-z])/, {
      message: "Atleast one small letter",
      name: "small letter",
      excludeEmptyString: true,
    })
    .matches(/[a-z]/, "Atleast one small letter")
    .matches(/[A-Z]/, "Atleast one capital letter")
    .matches(/(\d)/, "Atleast one number")
    // .matches(/(\W)/, "Atleast one special character")
    .required("Password is required"),

  full_name: yup
    .string()
    .nullable()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
    .max(30, "Name can not be longer than 30 characters"),
    account_holder_name: yup
    .string()
    .nullable()
    .required("Account Holder Name is required")
    .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
    .max(30, "Name can not be longer than 30 characters"),

  first_name: yup
    .string()
    .nullable()
    .required("First name is required")
    .matches(/^[A-Za-z]+$/, "Please enter a valid first name")
    .max(30, "Name can not be longer than 30 characters"),

  name_on_card: yup
    .string()
    .nullable()
    .required("Cardholder Name is required")
    .matches(/\S/, "Card details is required")
    .max(30, "Cardholder Name can not be longer than 30 characters"),

  last_name: yup
    .string()
    .nullable()
    .required("Last name is required")
    .matches(/^[A-Za-z]+$/, "Please enter a valid last name")
    .max(30, "Name can not be longer than 30 characters"),

  email: yup
    .string()
    .email()
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z0-9-.]{1,}[a-z]{1,}$/,
      "Email is not valid"
    ).required("Email is required"),

  source: yup
    .string()
    .nullable()
    .required("Card number is  required")
    .typeError("Card number is  required"),

  cvc: yup.string().nullable().required("CVV number is required"),

  phone: yup.string().nullable().required("Phone number is required"),

  dob: yup
    .date()
    .nullable()
    .typeError("Invalid date")
    .required("Date of birth is required")
    .test(
      "is-adult",
      "You must be at least 18 years old",
      function (value) {
        // Check if the user is at least 18 years old
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        return new Date(value) <= eighteenYearsAgo;
      }
    ),
  insurance_expiry_date: yup
    .date()
    .nullable()
    .typeError("Invalid date")
    .required("Expiry date is required"),
  gender: yup.string().nullable().required("Gender is required"),

  expiray_card: yup
    .date()
    .nullable()
    .typeError("Invalid date")
    .required("Expiry date is required"),

  zip_code: yup
  .string()
  .required("Zip code is required")
  .matches(/^\d{5}(-\d{4})?$/, "Zip code must be in a valid format")
// .max(5, "Zip code should not exceed 5 characters"),
,

  street: yup
    .string()
    .nullable()
    .required("Street Address is required")
    .matches(/\S/, "Street Address is required")
    .max(30, "Street Address can not be longer than 30 characters"),

  suite: yup
    .string()
    .nullable()
    .required("Suite No. is required")
    .matches(/\S/, "Suite No. is required")
    .max(30, "Suite No. can not be longer than 30 characters"),

  city: yup
    .string()
    .nullable()
    .required("City is required")
    .matches(/^[A-Za-z ]+$/, "City must contain only letters"),

  state: yup.object().shape({
    name: yup.string().nullable().required("State is Required"),
  }),
  stateCollision: yup
    .string()
    .nullable()
    .required("State is required")
    .matches(/^[A-Za-z ]+$/, "State must contain only letters"),
    
    country: yup
    .string()
    .nullable()
    .required("State is required")
    .matches(/^[A-Za-z ]+$/, "State must contain only letters"),
    
  address_1: yup
    .string()
    .required("Street address is required")
    .max(100, "Street address must not exceed 100 characters"),
  address_2: yup
    .string()
    // .required("Street address is required")
    .max(100, "Street address must not exceed 100 characters"),
  address_3: yup
    .string()
    // .required("Street address is required")
    .max(100, "Street address must not exceed 100 characters"),
    
    cardNumber: yup
    .string()
    .required('Card number is required')
    .matches(/^\d{16}$/, 'Invalid card number'),
    bank_aba_number: yup
    .string()
    .required('ABA number is required'),
    // .matches(/^\d{9}$/, "Invalid ABA Number"),
    bank_account_number: yup
    .string()
    .required('Account number is required'),
    // .matches(/^\d{9}$/, 'Invalid account number'),
    bank_account_type:yup.string().required("Account type is required."),
    bank_name: yup
    .string()
    .nullable()
    .required("Bank name is required")
    .matches(/^[A-Za-z ]+$/, "Please enter a valid bank name")
    .max(30, "Bank Name can not be longer than 30 characters"),

  expiryDate: yup
    .string()
    .required('Expiration date is required')
    .matches(
      /^(0[1-9]|1[0-2])\/\d{2}$/,
      'Invalid expiry date.'
    ),
    

    cvv: yup
    .string()
    .required('CVV is required')
    .matches(/^\d{3,4}$/, 'Invalid CVV'),


    
  description: yup.string().required("Description is required"),
  // briefDescription: yup.string().required("Brief Description is required"),
  briefDescription: yup.string().test('conditional-validation', 'Brief Description is required', function (value) {
    const isPropertyDamage = this.parent.isPropertyDamage;
    if (isPropertyDamage  && !value) {
      return false;
    }
    return true;
  }),
  languages: yup
    .array()
    .nullable()
    .required("Select at least 1")
    .of(yup.object()),

  policy_no: yup
    .string()
    .matches(/^\d{2}-\d{6}-\d{2}$/, "Policy No. must be in format ##-######-##")
    .nullable()
    .required("Policy No. is required")
    .typeError("Policy No. is required"),

  policy_id: yup
  .number()
  .required("Policy No. is required")
  .typeError("Policy No. is required"),

  policy_name: yup
    .string()
    .nullable()
    .required("Policy name is required")
    .matches(/\S/, "Policy Name is required")
    .max(30, "Name can not be longer than 30 characters"),

  policy_date: yup
    .date()
    .nullable()
    .typeError("Invalid date")
    .required("Policy date is required"),

  policy_time: yup
    .date()
    .nullable()
    .typeError("Invalid time")
    .required("Policy time is required"),

  paperlessEnrolled: yup
    .boolean()
    .required("Please specify if enrolled in paperless"),
};
