import {
  DriverVehicleDetailAPIResponse,
  FetchClaimsDraftResponse,
  IFetchClaimsDraftResponse,
} from "pages/Claims/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "services/axiosInstance";

// Function to submit collision details via a POST request
const useCollisionDetailsQuery = (data: any) => {
  const method = "POST";
  const url = `/v1/claims/collision-rollover`;
  return axios({ method, url, data });
};

// React Query hook for posting collision details
export const PostCollisionDetailsApi = () => {
  const queryClient = useQueryClient();
  return useMutation(useCollisionDetailsQuery, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries(["GET_CLAIMS_DETAIL"]);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to submit theft details via a POST request
const useTheftQuery = (data: any) => {
  const method = "POST";
  const url = `/v1/claims/auto-theft`;
  return axios({ method, url, data });
};

// React Query hook for posting theft details
export const PostTheftApi = () => {
  return useMutation(useTheftQuery, {
    onSuccess: (res: any) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch state details via a GET request
export const FetchStateDetail = () => {
  const method = "GET";
  const url = `/v1/states?page=1&pageSize=200&sort=ASC`;
  return axios({ method, url });
};

// React Query hook for fetching state details
export const FetchStateDetailAPI = () => {
  return useQuery(["GET_STATE_DETAIL"], () => FetchStateDetail(), {
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch country details via a GET request
export const FetchCountryDetail = () => {
  const method = "GET";
  const url = `/v1/countries?page=1&pageSize=250&sort=ASC`;
  return axios({ method, url });
};

// React Query hook for fetching country details
export const FetchCountryDetailAPI = () => {
  return useQuery(["GET_COUNTRIES_DETAIL"], () => FetchCountryDetail(), {
    onSuccess: () => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch driver and vehicle details via a GET request
export const FetchDriverVehicleDetail =
  (): Promise<DriverVehicleDetailAPIResponse> => {
    const method = "GET";
    const url = `/v1/policy/vehicle/drivers`;
    return axios({ method, url });
  };

// React Query hook for fetching driver and vehicle details
export const FetchDriverVehicleDetailAPI = () => {
  return useQuery<DriverVehicleDetailAPIResponse>(
    ["GET_DRIVER_DETAIL"],
    () => FetchDriverVehicleDetail(),
    {
      onSuccess: (res) => {},
      onError: (error) => {
        console.log("Error:", error);
      },
    }
  );
};

// Function to fetch policy details via a GET request
export const FetchPolicyDetail = () => {
  const method = "GET";
  const url = `/v1/policy`;
  return axios({ method, url });
};

// React Query hook for fetching policy details
export const FetchPolicyClaimDetailAPI = () => {
  return useQuery(["GET_POLICY_CLAIM_DETAIL"], () => FetchPolicyDetail(), {
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch claims draft details via a GET request
export const FetchClaimsDraftDetail =
  (): Promise<IFetchClaimsDraftResponse> => {
    const method = "GET";
    const url = `/v1/claims/draft`;
    return axios({ method, url });
  };

// React Query hook for fetching claims draft details
export const FetchClaimsDraftDetailAPI = () => {
  return useQuery<IFetchClaimsDraftResponse>(
    ["GET_CLAIMS_DRAFT_DETAIL"],
    () => FetchClaimsDraftDetail(),
    {
      onSuccess: (res) => {},
      onError: (error) => {
        console.log("Error:", error);
      },
    }
  );
};

// Function to fetch claims details via a GET request
export const FetchClaimsDetail = () => {
  const method = "GET";
  const url = `/v1/claims`;
  return axios({ method, url });
};

// React Query hook for fetching claims details
export const FetchClaimsDetailAPI = () => {
  return useQuery(["GET_CLAIMS_DETAIL"], () => FetchClaimsDetail(), {
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to update claim draft via a PATCH request
const updateClaimDraft = (data: any) => {
  const method = "PATCH";
  const url = `/v1/claims/draft`;
  return axios({ method, url, data });
};

// React Query hook for updating claim draft
export const UpdateClaimDraftAPI = () => {
  const queryClient = useQueryClient();
  return useMutation(updateClaimDraft, {
    onSuccess: (response) => {
      console.log({ response });
      queryClient.invalidateQueries("CLAIM_DRAFT");
    },
  });
};

// Function to fetch claims draft details for a specific claim via a GET request
export const fetchClaimsDraft = (
  claimId: string
): Promise<FetchClaimsDraftResponse> => {
  const method = "GET";
  const url = `/v1/claims/${claimId}/draft`;
  return axios({ method, url });
};

// React Query hook for fetching claims draft details for a specific claim
export const FetchClaimsDraftAPI = (claimId: string, enabled = true) => {
  return useQuery<FetchClaimsDraftResponse>(
    ["CLAIM_DRAFT", claimId],
    () => fetchClaimsDraft(claimId),
    {
      onSuccess: (res) => {},
      onError: (error) => {
        console.log("Error:", error);
      },
      enabled,
    }
  );
};

// Function to delete claims draft via a DELETE request
export const deleteClaimsDraft = async (claimId: string) => {
  const method = "DELETE";
  const url = `/v1/claims/${claimId}/draft`;
  return await axios({ method, url });
};

// React Query hook for deleting claims draft
export const useDeleteClaimsDraft = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteClaimsDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(["GET_CLAIMS_DRAFT_DETAIL"]);
    },
  });
};

// Function to fetch policy addresses via a GET request
export const fetchPolicyAddress = (
  ): Promise<FetchClaimsDraftResponse> => {
    const method = "GET";
    const url = `/v1/policy/addresses?page=1`;
    return axios({ method, url });
  };

// React Query hook for fetching policy addresses
export const FetchPolicyAddressAPI = () => {
    return useQuery<FetchClaimsDraftResponse>(
      ["POLICY_ADDRESS"],
      () => fetchPolicyAddress(),
      {
        onSuccess: (res) => {},
        onError: (error) => {
          console.log("Error:", error);
        },
      }
    );
  };
