// Importing necessary components from Material-UI
import { Grid, Typography, Divider, Button } from "@mui/material";
// Importing CSS for styling
import "./index.css";
// Importing CommonDialog component for modal dialog presentation
import CommonDialog from "components/Common/CommonDialog";
// Importing image assets
import FarmBureau from "assets/Images/farmBureau.svg";
// Importing API query functions
import { FetchPolicyDetailAPI } from "queries/PolicyQuery";

// Interface defining the props structure for the PolicyCard component
interface PolicyIdCardDialogProps {
  open: boolean; // Boolean to control the visibility of the dialog
  handleClose: () => void; // Function to close the dialog
  policyNumber?: any; // Policy number
  policyType?: string; // Type of the policy
  vehicle?: any; // Vehicle information
}

// PolicyCard is a functional component for displaying policy details
const PolicyCard = (props: PolicyIdCardDialogProps) => {
  const { open, handleClose, policyNumber, vehicle, policyType } = props;

  // Fetching policy details based on policyNumber and policyType
  let policyData;
  if (policyNumber && policyType) {
    const { data: policyDetail } = FetchPolicyDetailAPI(
      policyNumber,
      policyType
    );
    policyData = policyDetail && policyDetail.data;
  }

  // Function to handle the print action
  const handlePrint = () => {
    window.print();
  };

  // Render the PolicyCard component
  return (
    <CommonDialog
      open={open}
      onClose={() => handleClose()}
      title=""
      useCommonTitle={true}
      maxWidth="xs"
    >
      <Grid container spacing={2}>
        {/* Image and policy details presentation */}
        <Grid item xs={12} className="text-center h-image">

        <img
            src={FarmBureau}
            alt="Id Card Logo"
            height={"37.37px"}
            width={"247.67px"}
          />
        </Grid>
        {/* ... Additional details with Grid layout */}
        <Grid container item xs={12} spacing={2} justifyContent="center">
        <div className="max-w-[343px] justify-center">
            <Grid
              container
              item
              xs={12}
              spacing={2}
              direction="row"
              className="date-grid"
            >
              <Grid item xs={6} className="text-start mt-[-10px]">
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0"
                >
                  {policyData && (
                    <span>
                      {new Date(policyData.effectiveDate).toLocaleDateString(
                        "en-US"
                      )}
                    </span>
                  )}
                </Typography>
                <Typography
                  color="#545454"
                  className="font-normal text-xs leading-5 mb-4"
                >
                  Effective Through
                </Typography>
              </Grid>
              <Grid item xs={6} className="text-end mt-[-10px] pr-4">
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0"
                >
                  {policyData && (
                    <span>
                      {new Date(policyData.expirationDate).toLocaleDateString(
                        "en-US"
                      )}
                    </span>
                  )}
                </Typography>
                <Typography
                  color="#545454"
                  className="font-normal text-xs leading-5 mb-4"
                >
                  Expiration Date
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={2}
              className="card-content"
            >
              <Grid item sm={6}>
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  Policy No.
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  {policyData?.policyNo}
                </Typography>
              </Grid>
            </Grid>
            <Divider className="card-divider" />
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={2}
              className="card-content"
            >
              <Grid item sm={6}>
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  Agent Info
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  {policyData?.agent?.firstName + " "+policyData?.agent?.lastName} 
                </Typography>
              </Grid>
            </Grid>
            <Divider className="card-divider" />
            {policyData?.agent?.phone && (
              <Grid
                container
                item
                xs={12}
                direction="row"
                spacing={2}
                className="card-content"
              >
                <Grid item sm={6}>
                  <Typography
                    color="#333333"
                    className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                  >
                    Office
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography
                    color="#545454"
                    className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                  >
                    {policyData?.agent?.phone}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Divider className="card-divider" />
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={2}
              className="card-content"
            >
              <Grid item sm={6}>
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  Insured
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start"
                >
                  {/* Jenny Wilson */}
                  {policyData?.insuredName}
                </Typography>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start"
                >
                  {/* Cameron Williamson */}
                </Typography>
              </Grid>
            </Grid>
            
            <Divider className="card-divider" />
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={2}
              className="card-content"
            >
              <Grid item sm={6}>
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  VIN
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  {vehicle?.vin}
                </Typography>
              </Grid>
            </Grid>
            <Divider className="card-divider" />
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={2}
              className="card-content"
            >
              <Grid item sm={6}>
                <Typography
                  color="#333333"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  Description
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-medium text-base leading-5 m-auto pt-0 text-start mt-2"
                >
                  {vehicle?.make} {vehicle?.model}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="row"
              spacing={2}
              className="card-content"

            >
              <Button
                color="secondary"
                fullWidth
                type="submit"
                size="large"
                className="w-full items-center justify-center m-auto flex !bg-[#262626] text-white h-[44px] print:hidden"
                onClick={handlePrint}
              >
                <span className="font-inter font-medium text-[16px] leading-[28px] tracking-normal text-center">
                  Print
                </span>
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </CommonDialog>
  );

};

// Exporting the PolicyCard component
export default PolicyCard;
