import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import WestIcon from '@mui/icons-material/West'
import LabelledTextField from 'components/Common/LabelledTextField' // Update the import path if needed

interface Props {
  handleSendForgetPassLink: (email: string) => void
  setResetLinkError: (error: string) => void
  resetLinkError: string
  handleGoBack: () => void
  handlesetEmail :  (email: string) => void
  email: string
}

// Define the type for your form data
interface FormData {
  resetemail: string;
}

const schema = yup.object().shape({
  resetemail: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
})

const PasswordResetLink = ({ handleSendForgetPassLink, setResetLinkError, resetLinkError, handleGoBack, handlesetEmail, email }: Props) => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      resetemail : email || ""
    }
  })

  const onSubmit: SubmitHandler<FormData> = (data) => {
    handleSendForgetPassLink(data.resetemail)
  }

  useEffect(() => {
    setValue("resetemail", email)
  }, [])

  return (
    <Grid item container spacing={4}>
      <Grid item xs={12}>
        <div className='flex flex-col gap-6 w-full'>
          <LabelledTextField
            variant="outlined"
            labelText="Email"
            inputProps={{ style: { height: '12px' } }}
            placeholder="Enter your email"
            {...register('resetemail', {
              onChange: (e) => {
                setResetLinkError('')
                setValue('resetemail', e.target.value, { shouldValidate: true })
                handlesetEmail(e.target.value)
              },
            })}
            error={Boolean(errors.resetemail)}
            helperText={(errors.resetemail?.message as string) || ''}
            autoComplete="email"
            className="max-w-[343px] items-start justify-center m-auto flex h-8"
          />
          {resetLinkError.length !== 0 && (
            <div className="text-center text-xs text-red-600">
              {resetLinkError}
            </div>
          )}
          <div
            className="max-w-[343px] items-center rounded-md justify-center m-auto w-full flex custom-button h-12 cursor-pointer"
            onClick={handleSubmit(onSubmit)}
          >
            <span className="font-medium text-base leading-7">
              Send Reset Link
            </span>
          </div>
        </div>
      </Grid>
      {resetLinkError.length !== 0 && (
        <div
          className="max-w-[153px] mt-4 items-center justify-center m-auto flex border-black border-2 border-solid py-2 px-4 rounded-lg  cursor-pointer"
          onClick={handleGoBack}
        >
          <WestIcon className="mr-1 text-sm" />
          <span className="font-medium text-sm ml-2">
            Back to Login
          </span>
        </div>
      )}
    </Grid>
  )
}

export default PasswordResetLink
