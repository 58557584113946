import MoatLogo from "assets/Images/moat.png";
import { Link } from "react-router-dom";

export default function Branding() {
  return (
    <Link
      to={"https://moatit.com"}
      className="flex items-center gap-4 text-black no-underline"
    >
      <span>Powered By</span>
      <img src={MoatLogo} className="md:w-[50px] w-[70px]" alt="Brand Logo" />
    </Link>
  );
}
