import axios from "services/axiosInstance";
import axios2 from "services/AxiosInstanceV2";
import { getLocalStorageItem } from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import { useMutation, useQuery, useQueryClient } from "react-query";

// FetchBills: Function to fetch bill data from the server with optional query parameters
export const FetchBills = (
  page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  isFuture?: boolean,
  column?: string,
  search?: string
) => {
  const method = "GET";
  const params = {
    page,
    pageSize,
    sort,
    isFuture,
    column,
    search,
  };
  const url = `/v1/bills`;

  // Axios GET request with query parameters
  return axios({ method, url, params });
};

// FetchBillsAPI: React Query hook to fetch bill data, depends on authentication token
export const FetchBillsAPI = (
  page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  isFuture?: boolean,
  column?: string,
  search?: string
) => {
  // 'useQuery' to fetch data and manage the fetching state
  return useQuery(
    ["GET_BILLS", page, pageSize, sort, isFuture, column, search],
    () => FetchBills(page, pageSize, sort, isFuture, column, search),
    {
      // Only fetch if the auth token is present
      enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
      onError: (error) => {
        if (error) {
          console.log("Error:", error);
        }
      },
    }
  );
};

// FetchNextBill: Function to fetch the next bill due
export const FetchNextBill = () => {
  const method = "GET";
  const url = `/v1/bills/next-bill`;

  // Axios GET request for the next bill
  return axios({ method, url });
};

// FetchNextBillAPI: React Query hook to fetch the next bill data
export const FetchNextBillAPI = () => {
  // 'useQuery' to fetch next bill data
  return useQuery(["GET_NEXT_BILL"], () => FetchNextBill(), {
    // Only fetch if the auth token is present
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {
      // Success handling
    },
    onError: (error) => {
      if (error) {
        console.log("Error:", error);
      }
    },
  });
};


// FetchBills: Function to fetch bill data from the server with optional query parameters
export const FetchFundingAccounts = (
  page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  billerAccountId?: any
) => {
  const method = "GET";
  const params = {
    page,
    pageSize,
    sort,
    billerAccountId,
  };
  const url = `aci/funding-account`;
  return axios2({ method, url, params });
};

export const useFundingAccountsQuery = (
  {page,pageSize, sort , billerAccountId} :{page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  billerAccountId?: any}
) => {
  return useQuery(
    ["FUNDING_ACCOUNTS", page, pageSize, sort, billerAccountId],
    () => FetchFundingAccounts(page, pageSize, sort, billerAccountId),
    {
      enabled: (Boolean(billerAccountId)),
      onError: (error) => {
        if (error) {
          console.log("Error:", error);
        }
      },
    }
  );
};



export const deleteFundingAccount = async (data: any) => {
  const method = "DELETE";
  const url = `aci/funding-account`;
  return await axios2({ method, url, data });
};

export const useDeleteFundingAccountQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteFundingAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(["FUNDING_ACCOUNTS"]);
    },
  });
};



export const markPrimaryFundingAccount= async (data: any) => {
  const method = "PATCH";
  const url = `aci/funding-account/mark-primary`;
  return await axios2({ method, url, data });
};

export const useMarkPrimaryFundingAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(markPrimaryFundingAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(["FUNDING_ACCOUNTS"]);
    },
  });
};


export const makePaymentWithFFundingToken= async (data: any) => {
  const method = "POST";
  const url = `payments/make-payment`;
  return await axios2({ method, url, data });
};

export const useMakePaymentWithFFundingToken = () => {
  return useMutation(makePaymentWithFFundingToken, {
    onSuccess : () => {
      // 
    }
  });
};


export const saveFundingAccountCard = async (data: any) => {
  const method = "POST";
  const url = `/aci/funding-account/card`;
  return await axios2({ method, url, data });
};

export const useSaveFundingAccountCard = () => {
  const queryClient = useQueryClient();
  return useMutation(saveFundingAccountCard, {
    onSuccess: () => {
    queryClient.invalidateQueries(["FUNDING_ACCOUNTS"]);
  }});
};

export const saveFundingAccountACH = async (data: any) => {
  const method = "POST";
  const url = `/aci/funding-account/ach`;
  return await axios2({ method, url, data });
};

export const useSaveFundingAccountACH = () => {
  const queryClient = useQueryClient();
  return useMutation(saveFundingAccountACH, {
    onSuccess: () => {
    queryClient.invalidateQueries(["FUNDING_ACCOUNTS"]);
  }});
};

// FetchNextBill: Function to fetch the next bill due
export const SyncPolicyDocs = (userId : any) => {
  const method = "GET";
  const url = `/v1/policy/sync-docs`;
  const params = { userId }

  // Axios GET request for the next bill
  return axios({ method, url, params });
};

// FetchNextBillAPI: React Query hook to fetch the next bill data
export const GetSyncPolicyDocs = (userId : any) => {
  // 'useQuery' to fetch next bill data
  return useQuery(["GET_SYNC_POLICY_DOCS"], () => SyncPolicyDocs(userId), {
    // Only fetch if the auth token is present
    enabled: Boolean(userId),
    onSuccess: (res) => {
      // Success handling
    },
    onError: (error) => {
      if (error) {
        console.log("Error:", error);
      }
    },
  });
};