//@ts-nocheck
import React, { useState } from "react";
import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
  Switch,
} from "@mui/material";
import InsuranceIcon from "assets/Icons/Insurance Icon.svg";
import FarmInsuranceIcon from "assets/Icons/Farm Insurance Icon.svg";
import HomeInsuranceIcon from "assets/Icons/Home Insurance Icon.svg";
import UmbrellaInsuranceIcon from "assets/Icons/UmbrellaInsuranceIcon.svg"
import PolicyCard from "components/Policy/PolicyIdCard";
import { useNavigate } from "react-router-dom";
import {
  FetchPoliciesAPI,
  FetchPolicySyncAPI,
  UseUpdatePaperlessEnrolledAPI,
  useDeletePolicyApi,
} from "queries/PolicyQuery";
import { useQueryClient } from "react-query";
import styled from "@emotion/styled";
import deleteIcon from "assets/Images/deleteIcon.svg";
import CommonDialog from "components/Common/CommonDialog";
import HandleErrorResponse from "helper/HandleErrorResponse";

// Policies component: Displays a list of insurance policies
const Policies = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [policyNumber, setPolicyNumber] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const queryClient = useQueryClient();
  const deletePolicyMutation = useDeletePolicyApi();

  // const [paperlessEnrolled, setPaperlessEnrolled] = useState({policyId: null, isEnrolled: false});
  const { mutate: draftData } = UseUpdatePaperlessEnrolledAPI();

  // Function to handle opening the policy card dialog
  const openPolicyCardDialog = (policy: any) => {
    setPolicyNumber(policy);
    setOpen(true);
  };

  // Function to handle closing the dialog

  // const handleDelete =  (policyNum: string) => {
  //   try {
  //     // Trigger the deleteClaimsDraftMutation
  //     deletePolicyMutation.mutate(policyNum);
  //   } catch (error) {
  //     console.error('Error deleting policy:', error);
  //   }
  // };
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function to navigate to policy details
  const navigateToDetails = (policyNumber: any, policyType: string) => {
    setTimeout(() => {
      navigate(`/policy-details?policyNo=${policyNumber}&type=${policyType}`);
    }, 10);
  };

  // Fetching policy data
  let policyList: any = [];
  const { data: policies } = FetchPoliciesAPI();
  const { data: policy } = FetchPolicySyncAPI();


  if (policies) {
    policyList = policies.data;
  }

  // Function to open modal for policy deletion confirmation
  const handleOpenModal = (policyNum: any) => {
    setPolicyNumber(policyNum);
    setOpenModal(true);
  };

  // Function to handle API call for policy deletion
  const handleApiCall = () => {
    try {
      // Trigger the deleteClaimsDraftMutation
      deletePolicyMutation.mutate(policyNumber);
      setOpenModal(false);
    } catch (error) {
      console.error("Error deleting policy :", error);
    }
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#BB3330",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleSwitchToggle = (policyId, paperlessEnrolled) => {
    draftData(
      {
        policyId,
        paperlessEnrolled: !paperlessEnrolled,
      },
      {
        onSuccess: () => {
          if (!paperlessEnrolled) {
            // Opening a link to the electronic delivery consent form if the switch is turned on
            const url = `https://moatit-dev-fbins.s3.us-west-2.amazonaws.com/documents/electronic_delivery_consent.pdf`;
            window.open(url, "_blank");
          }
        },
        onError: (err) => {
          HandleErrorResponse(err);
        },
      }
    );
  };

  return (
    <div className="bg-white">
      <Grid container className="mt-1 h-auto max-h-[100vh]" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="w-[110px] h-[32px] mt-4 ml-6 font-semibold text-[20px] leading-[32px]"
          >
            My Policies
          </Typography>
          <Typography
            variant="subtitle1"
            color="#545454"
            className="ml-6 font-normal text-[12px] leading-[16px]"
          >
            Keep track of your insurance coverage and renewal dates
          </Typography>
        </Grid>
        {policyList &&
          policyList.length > 0 &&
          policyList.map((policy: any, index: number) => (
            <Grid item key={policy.policyNo} className="px-2 container mt-2">
              <Card className="py-2 px-4">
                <CardContent>
                  <ButtonBase
                    component={Grid}
                    container
                    className=" w-full"
                    onClick={() =>
                      navigateToDetails(policy.policyNo, policy.type)
                    }
                  >
                    <Grid container direction="row">
                      <Grid item className="pr-4">
                        {policy.type === "autoPolicy" && (
                          <img src={InsuranceIcon} alt="Auto Insurance" />
                        )}
                        {policy.type === "homePolicy" && (
                          <img src={HomeInsuranceIcon} alt="Home Insurance" />
                        )}
                        {policy.type === "farmPolicy" && (
                          <img src={FarmInsuranceIcon} alt="Farm Insurance" />
                        )}
                        {policy.type === "umbrellaPolicy" && (
                          <img
                            src={UmbrellaInsuranceIcon}
                            alt="Farm Insurance"
                          />
                        )}
                      </Grid>

                      <Grid item direction="column">
                        <Typography
                          variant="subtitle1"
                          color="text.secondaryInfo"
                          className="font-semibold text-[14px] leading-[20px]"
                        >
                          {policy.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className="font-normal text-[10px] leading-[14px]"
                        >
                          Policy No. {policy.policyNo}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" className="mt-2">
                      <Grid
                        container
                        item
                        xs={12}
                        sm={10}
                        direction="row"
                        className="gap-x-3"
                      >
                        {policy?.vechiles?.length > 0 && (
                          <Grid item xs={12} sm={5} direction="column">
                            <Typography
                              color="#757575"
                              className="text-start font-normal text-[12px] leading-[20px]"
                            >
                              Vehicle
                            </Typography>
                            <Typography
                              variant="body1"
                              color="#262626"
                              className="text-start font-medium text-[14px] leading-[24px]"
                            >
                              {policy.vechiles[0].make}{" "}
                              {policy.vechiles[0].model}{" "}
                              {policy.vechiles.length > 1 &&
                                ` +${policy.vechiles.length - 1}`}
                            </Typography>
                          </Grid>
                        )}
                        {policy?.registrationNumber && (
                          <Grid item xs={12} sm={4} direction="column">
                            <Typography
                              color="#757575"
                              className="text-start font-normal text-xs"
                            >
                              Registration No.
                            </Typography>
                            <Typography
                              variant="body1"
                              color="#262626"
                              className="text-start font-medium text-sm"
                            >
                              {policy.registrationNumber}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={4} direction="column">
                          <Typography
                            color="#757575"
                            className="text-start font-normal text-[12px] leading-[20px]"
                          >
                            Effective through
                          </Typography>
                          <Typography
                            variant="body1"
                            className={`text-start font-medium text-[14px] leading-[24px] ${
                              policy.status === "active"
                                ? "text-[#07794C]"
                                : "text-[#B32306]"
                            }`}
                          >
                            {new Date(policy.expirationDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} sm={2} direction="row">
                        <Grid item xs={12} sm={12} direction="column">
                          {policy.status === "expired" && (
                            <Typography
                              variant="body1"
                              color="#B32306"
                              style={{
                                backgroundColor: "#FDF3F2",
                                textAlign: "center",
                              }}
                              className="font-semibold text-sm"
                            >
                              {policy.status}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </ButtonBase> */}
                    {/* {policy.name === "Personal Auto" && <Grid container className="flex mt-4">
                      <Grid item xs={12}>
                        <Button
                          color="secondary"
                          fullWidth
                          size="large"
                          className="items-center justify-center m-auto flex h-8 border-[1px] border-solid border-[#545454]"
                          // onClick={() => openPolicyCardDialog(policy.policyNo)}
                        >
                          <span className="font-normal text-xs leading-4 text-[#333333]">
                            View ID
                          </span>
                        </Button>
                      </Grid>
                    </Grid>} */}
                  </ButtonBase>
                  <div className="flex justify-between items-center align-middle">
                    {/* <Grid
                      item
                      xs={12}
                      className="flex flex-col items-center my-5"
                    >
                      <FormControlLabel
                        className="gap-x-2.5"
                        control={
                          <CustomSwitch
                            checked={!!policy.paperlessEnrolled}
                            onChange={() =>
                              handleSwitchToggle(
                                policy.id,
                                policy.paperlessEnrolled
                              )
                            }
                          />
                        }
                        label={
                          <Typography fontSize={14}>
                            Enrolled in paperless
                          </Typography>
                        }
                      />
                    </Grid> */}

                    <Grid container className="flex">
                      <Grid item xs={12}>
                        <Button
                          color="secondary"
                          fullWidth
                          size="large"
                          className="items-center justify-center m-auto flex h-8  border-none hover:border-none"
                          onClick={() => handleOpenModal(policy.policyNo)}
                        >
                          <img
                            src={deleteIcon}
                            alt="deleteImg"
                            className="pr-[10px]"
                          />
                          <Typography
                            className="font-normal text-[10px] leading-4"
                            color={"#DC2804"}
                          >
                            Remove Policy
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      <PolicyCard
        open={open}
        handleClose={handleClose}
        policyNumber={policyNumber}
      />
      <CommonDialog
        open={openModal}
        onClose={handleCloseModal}
        title="Alert"
        maxWidth="xs"
        actions={
          <div className="flex justify-evenly gap-3 items-center w-full pb-4">
            <Button
              className="w-[164px] h-11 font-semibold text-4 font-inter leading-7 text-center border border-[#545454] shadow-[0px_2px_8px_0px_rgba(15,15,15,0.06)] hover:bg-gray-700 hover:text-white hover:border-none"
              onClick={() => handleApiCall()}
            >
              Yes
            </Button>
            <Button
              className="w-[164px] h-11 font-semibold text-4 font-inter leading-7 text-center border border-[#545454] shadow-[0px_2px_8px_0px_rgba(15,15,15,0.06)] hover:bg-gray-700 hover:text-white hover:border-none"
              onClick={() => handleCloseModal()}
            >
              No
            </Button>
          </div>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              className="text-center pt-3 mb-[-9px]"
              fontSize={14}
              fontWeight={400}
            >
              Are you sure you want to remove this policy?
            </Typography>
          </Grid>
        </Grid>
        <Grid container gap={"32px"}></Grid>
      </CommonDialog>
    </div>
  );
};

export default Policies;
