/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

// Import necessary dependencies

import axios from "axios";
import { getLocalStorageItem ,setLocalStorageItem} from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import {
  RefreshAccessToken,
  handleGetNewAuthtokens,
  handleRefreshAuthtokens,
} from "queries/AuthQueries";

// Create an Axios instance with a base URL from environment variables and default headers
const AxiosInstanceV2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL+"/v2",
  headers: {
    "Content-Type": "application/json",
    "x-secret-key": process.env.REACT_APP_SECRET_ACI_KEY
  },
});

// Function to handle user logout and redirect to the login page
const handleLogoutRedirectUser = () => {
  localStorage.removeItem(localstorageKeys.AUTH_TOKEN);
  window.location.href = "/login";
};

// Interceptor for requests to add the auth token if available
AxiosInstanceV2.interceptors.request.use(async (config: any) => {
  const authTokens = getLocalStorageItem(localstorageKeys.AUTH_TOKEN);
  if (authTokens) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authTokens}`,
      },
    };
  }
  return config;
});

// Interceptor for responses to handle 401 Unauthorized status
AxiosInstanceV2.interceptors.response.use(
  (response) => response.data,
  async (error) => {
  
    const originalRequest = error.config;
    const authTokens = getLocalStorageItem(localstorageKeys.AUTH_TOKEN);

    // Handle token refresh if 401 status and request not yet retried
    if (
      error?.response?.status === 401 &&
      authTokens &&
      !originalRequest._retry
    ) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      try {
        const access_token = await RefreshAccessToken();
       
         
          // setLocalStorageItem(localstorageKeys.AUTH_TOKEN, access_token?.data?.oauthToken);
          // setLocalStorageItem(localstorageKeys.REFRESH_TOKEN, access_token?.data?.oauthRefreshToken);

        if (access_token) {
          handleGetNewAuthtokens(access_token);
          console.log("access_token",access_token)
          originalRequest.headers.Authorization = `Bearer ${access_token?.data?.oauthToken}`;
          return await AxiosInstanceV2(originalRequest);
        }
      } catch (errors: any) {
        // console.log("catch 401",errors);
        handleLogoutRedirectUser();
      }
    }if (error?.response?.status === 403 && error?.response?.data?.message ==='Invalid refresh token!') {
      // console.log("error 403",error)
      // Redirect to login if 401 error after retry
      handleLogoutRedirectUser();
      // alert(JSON.stringify(error))
      
    }
    

    return Promise.reject(error.response.data);
  }
);

// Export the Axios instance for use throughout the application
export default AxiosInstanceV2;



// auth ->
// ref -> 

// if auth , ref -> 

// if auth ,ref(of) ->  