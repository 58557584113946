import axios from "services/axiosInstance";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CardDetailsResponse } from "../types";

/**
 * Fetches card details from the server.
 */

/**
 * Custom hook to fetch all card details using React Query.
 */

/**
 * Makes an API call to remove a specific card detail.
 */
export const removeCardDetails = (cardNumber: string) => {
  const method = "DELETE";
  const url = `v1/cards/${cardNumber}/remove-card`;
  return axios({ method, url });
};

/**
 * Custom hook to remove a card detail using React Query's useMutation.
 */
export const useRemoveCardDetailsAPI = () => {
  const queryClient = useQueryClient();

  return useMutation(removeCardDetails, {
    onSuccess: () => {
      // Invalidate and refetch queries after successful deletion
      queryClient.invalidateQueries(["ALL_CARD_DETAILS"]);
    },
  });
};

/**
 * Adds card details to the server.
 */
export const addCardDetails = (data: any) => {
  const method = "POST";
  const url = `v1/cards/add-card`;
  return axios({ method, url, data });
};

/**
 * Custom hook to add card details using React Query's useMutation.
 */
export const useAddCardDetailsAPI = () => {
  const queryClient = useQueryClient();
  return useMutation(addCardDetails, {
    onSuccess: () => {
      // Invalidate and refetch queries after successful addition
      queryClient.invalidateQueries(["ALL_CARD_DETAILS"]);
    },
  });
};

/**
 * Updates card details on the server.
 */
const updateCardDetails = (data: any) => {
  const method = "PATCH";
  const url = `v1/cards/update-card`;
  return axios({ method, url, data });
};

/**
 * Custom hook to update card details using React Query's useMutation.
 */
export const useUpdateCardDetailsAPI = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCardDetails, {
    onSuccess: (response) => {
      // Log response and invalidate queries on success
      console.log({ response });
      queryClient.invalidateQueries("ALL_CARD_DETAILS");
    },
  });
};

/**
 * Makes a payment through the API.
 */
const makePayment = (data: any) => {
  const method = "POST";
  const url = `v1/payments/make-payment`;
  return axios({ method, url, data });
};

/**
 * Custom hook to make a payment using React Query's useMutation.
 */
export const useMakePaymentAPI = () => {
  return useMutation(makePayment, {
    onSuccess: () => {
      // Actions on successful payment
    },
  });
};
