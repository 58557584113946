import AuthPage from "pages/Auth";
import React from "react";
import Subscription from "../pages/Subscription";
import Dashboard from "pages/Dashboard";
import Policies from "pages/Policy/PolicyList";
import PolicyDetails from "pages/Policy/PolicyDetail";
import Claims from "pages/Claims/ClaimsList";
import ClaimsForm from "pages/Claims/ClaimForm";
import SelectClaim from "pages/Claims/SelectClaim";
import Bills from "pages/Bills";
import CollisionDetails from "pages/Claims/Collision/DetailsPage";
import DescribeIncident from "pages/Claims/Collision/DescribeIncident";
import AboutVehicle from "pages/Claims/Collision/AboutVehicle";
import AddPassengers from "pages/Claims/Collision/AddPassengers";
import ContactDetailsForm from "pages/Claims/Collision/ContactDetails";
import Summary from "pages/Claims/Collision/Summary";
import ClaimSuccessful from "pages/Claims/Collision/SuccessfulPage";
import AddWitness from "pages/Claims/Collision/AddWitness";
import Benefits from "pages/Benefits";
import Travel from "pages/Travel";
import BenefitsDiscount from "pages/Benefits/benefitsDiscount";
import TheftType from "pages/Claims/Theft/TheftType";
import MapMyBenefit from "pages/Benefits/mapMyBenefit";
import BrokenAndOtherSuccessfulPage from "pages/Claims/Collision/BrokenAndOthersSuccessPage";
import OtherVehicle from "pages/Claims/Collision/OtherVehicle";
import AddCard from "pages/Bills/MakePayment/AddCard";
import SelectPayment from "pages/Bills/MakePayment/SelectPayment";
import PaymentDetail from "pages/Bills/MakePayment/PaymentDetail";
import Avis from "pages/Avis";
import BudgetDetails from "pages/BudgetDetails";
import PaymentSuccessful from "pages/Bills/MakePayment/PaymentSuccessful";
import PaymentDenied from "pages/Bills/MakePayment/PaymentDenied";
import SelectMethod from "pages/Bills/MakePayment/SelectMethod";
import DirectDebit from "pages/Bills/MakePayment/ACHdirectDebit";
import Offers from "pages/Benefits/Offers";
import ManagePaymentMethods from "pages/Bills/MakePayment/ManagePaymentMethods";
import PaymentMethodCard from "pages/Bills/MakePayment/PaymentMethodCard";
import PaymentMethodACH from "pages/Bills/MakePayment/PaymentMethodACH";

export const NavigationLinks = [
  {
    name: "Leads",
    redirectLink: "/leads",
  },
  {
    name: "Providers Details",
    redirectLink: "/provider-details",
  },
];

type RouteType = {
  pathname: string;
  title: string;
  isPublic: boolean;
  showHeader?: boolean;
  showAgent?: boolean;
  showAssistance?: boolean;
  isLogin?: boolean;
  isDetails?: boolean;
  element: React.ReactNode;
};

export const routes: Record<string, RouteType> = {
  REDIRECTOR: {
    pathname: "/",
    title: "Login",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <AuthPage />,
  },
  LOGIN: {
    pathname: "/login",
    title: "Login",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <AuthPage />,
  },
  SIGNUP: {
    pathname: "/signup",
    title: "SignUp",
    isPublic: true,
    element: <AuthPage />,
  },
  FORGOT_PASSWORD: {
    pathname: "/forgot-password",
    title: "Forgot Password",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <Subscription />,
  },
  VERIFY_EMAIL: {
    pathname: "/verify-register",
    title: "Login",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <Subscription />,
  },
  TERMS_CONDITIONS: {
    pathname: "/terms",
    title: "Login",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <Subscription />,
  },
  PRIVACY_POLICY: {
    pathname: "/privacy",
    title: "Login",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <Subscription />,
  },
  DASHBOARD: {
    pathname: "/dashboard",
    title: "Dashboard",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    isLogin: false,
    element: <Dashboard />,
  },
  POLICIES: {
    pathname: "/policies",
    title: "Policies",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    element: <Policies />,
  },
  POLICYDETAIL: {
    pathname: "/policy-details",
    title: "PolicyDetail",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    isDetails: true,
    element: <PolicyDetails />,
  },
  BILLS: {
    pathname: "/bills",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    element: <Bills />,
  },
  PAYMENTADDCARD: {
    pathname: "/add-card",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    showAssistance: false,
    element: <AddCard />,
  },
  // SELECTPAYMENTMETHOD: {
  //   pathname: "/select-method",
  //   title: "Bills",
  //   isPublic: false,
  //   showHeader: true,
  //   showAgent: true,
  //   showAssistance: true,
  //   element: <SelectPayment />,
  // },
  SELECTPAYMENTMETHOD: {
    pathname: "/select-payment-method",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <SelectMethod />,
  },
  MANAGEPAYMENTMETHODS: {
    pathname: "/manage-payment-method",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    showAssistance: false,
    element: <ManagePaymentMethods />,
  },
  ADDPAYMENTMETHODCARD: {
    pathname: "/add-payment-method-card",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    showAssistance: false,
    element: <PaymentMethodCard />,
  },
  ADDPAYMENTMETHODACH: {
    pathname: "/add-payment-method-ach",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    showAssistance: false,
    element: <PaymentMethodACH />,
  },
  ACHDIRECTDEBIT: {
    pathname: "/direct-debit",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <DirectDebit />,
  },

  PAYMENTSUCCESS: {
    pathname: "/payment-success",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    showAssistance: false,
    element: <PaymentSuccessful />,
  },
  PAYMENTDENIED: {
    pathname: "/payment-denied",
    title: "Bills",
    isPublic: false,
    showHeader: true,
    showAgent: false,
    showAssistance: false,
    element: <PaymentDenied />,
  },
  BENEFITS: {
    pathname: "/benefits",
    title: "Benefits",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    element: <Benefits />,
  },
  TRAVEL: {
    pathname: "/benefits/travel",
    title: "Travel",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    element: <Travel />,
  },
  DISCOUNTS: {
    pathname: "/benefits/discounts",
    title: "Discounts",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    element: <BenefitsDiscount />,
  },
  MAPMYBENEFITS: {
    pathname: "/benefits/map-benefits",
    title: "MapBenefits",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: false,
    element: <MapMyBenefit />,
  },
  CLAIMS: {
    pathname: "/claims",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <Claims />,
  },
  REPORTCLAIM: {
    pathname: "/report-claim",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <ClaimsForm />,
  },
  SELECTCLAIM: {
    pathname: "/select-claim",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <SelectClaim />,
  },
  COLLISIONDETAIL: {
    pathname: "/collision-details",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <CollisionDetails />,
  },
  DESCRIBEINCIDENTCOLLISION: {
    pathname: "/describe-incident",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <DescribeIncident />,
  },
  ABOUTVEHICLE: {
    pathname: "/about-vehicle",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <AboutVehicle />,
  },
  OTHERVEHICLE: {
    pathname: "/other-vehicle",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <OtherVehicle />,
  },
  ADDOTHERPASSENGER: {
    pathname: "/add-passenger",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <AddPassengers />,
  },
  ADDWITNESS: {
    pathname: "/add-witness",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <AddWitness />,
  },
  CONTACTDETAILS: {
    pathname: "/contact-details",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <ContactDetailsForm />,
  },
  SUMMARY: {
    pathname: "/summary",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <Summary />,
  },
  RESET_PASSWORD: {
    pathname: "/reset-password",
    title: "Forgot Password",
    isPublic: true,
    showHeader: false,
    showAgent: false,
    element: <AuthPage />,
  },
  CLAIMSUCCESSFUL: {
    pathname: "/claim-success",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <ClaimSuccessful />,
  },
  BROKENANDOTHERSUCCESSFUL: {
    pathname: "/other-claim-success",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <BrokenAndOtherSuccessfulPage />,
  },

  SELECTTHEFT: {
    pathname: "/select-theft-type",
    title: "Claims",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    showAssistance: true,
    element: <TheftType />,
  },
  SUBSCRIPTION: {
    pathname: "/sign-up",
    title: "Subscription",
    isPublic: false,
    showHeader: false,
    showAgent: false,
    element: <Subscription />,
  },
  AVIS: {
    pathname: "/benefits/discounts",
    title: "Benefit Detail",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    element: <Avis />,
  },
  BUDGETDETAILS: {
    pathname: "/benefits/discounts/budget",
    title: "Budget Details",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    element: <BudgetDetails />,
  },
  OFFERDETAILS: {
    pathname: "/benefits/discounts/offer",
    title: "Budget Details",
    isPublic: false,
    showHeader: true,
    showAgent: true,
    element: <Offers />,
  },
};

export const routeKey: Record<string, string> = Object.assign(
  {},
  ...Object.keys(routes)
    .map((routeKey) => {
      const route = routes[routeKey as keyof typeof routes];
      return route ? { [route.pathname]: routeKey } : null;
    })
    .filter(Boolean)
);
