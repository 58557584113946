//@ts-nocheck
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import InsuranceIcon from "assets/Icons/Insurance Icon.svg";
import FarmInsuranceIcon from "assets/Icons/Farm Insurance Icon.svg";
import HomeInsuranceIcon from "assets/Icons/Home Insurance Icon.svg";
import UmbrellaInsuranceIcon from "assets/Icons/UmbrellaInsuranceIcon.svg";
import ArrowDropDownIcon from "assets/Icons/arrow-down.svg";
import ExpandMoreIcon from "assets/Icons/expand_more.svg";
import PolicyCard from "components/Policy/PolicyIdCard";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  FetchPolicyDetailAPI,
  FetchPolicyDocDetailAPI,
  FetchPolicyServeSyncAPI,
} from "queries/PolicyQuery";
import { IFetchPolicyDocDetail } from "queries/types";
import { TimeFormatter } from "utils/formatDate";

// Hardcoded policy and billing document data for demonstration
const policyDocuments = [
  {
    policyName: "City Squire Policy",
    updatedTime: "09/23/2022",
  },
  {
    policyName: "ACV Roof Endorement",
    updatedTime: "09/23/2022",
  },
  {
    policyName: "ACV Roof Endorement",
    updatedTime: "09/23/2022",
  },
  {
    policyName: "City Squire Policy",
    updatedTime: "09/23/2022",
  },
  {
    policyName: "ACV Roof Endorement",
    updatedTime: "09/23/2022",
  },
  {
    policyName: "ACV Roof Endorement",
    updatedTime: "09/23/2022",
  },
];

const billingDocuments = [
  {
    name: "Document 1",
    updatedOn: "09/23/2022",
  },
  {
    name: "Document 2",
    updatedOn: "09/23/2022",
  },
  {
    name: "Document 3",
    updatedOn: "09/23/2022",
  },
];

const docsEnums = {
  autoPolicy: "policy",
  homePolicy: "policy",
  umbrellaPolicy: "umbrella",
  billingDocs: "billing",
};
interface InfoGridProps {
  title: string;
  value: string | number;
}

// Component for rendering individual information grids

const InfoGrid: FunctionComponent<InfoGridProps> = ({ title, value }) => (
  <Grid item direction="column" sm={2} className="justify-start">
    <Typography
      variant="subtitle1"
      color="#545454"
      className="font-normal text-xs leading-[12px]"
    >
      {title}
    </Typography>
    <Typography className="font-medium text-base leading-7 mt-1">
      {value}
    </Typography>
  </Grid>
);
// Main component for displaying policy details
const PolicyDetails = () => {
  const [open, setOpen] = useState(false);
  const [vehicle, setVehicle] = useState(null);

  // Function to handle policy card dialog opening
  const openPolicyCardDialog = (vehicle: any) => {
    setVehicle(vehicle);
    setOpen(true);
  };

  // Function to handle dialog closing
  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policyNo");
  const policyType = searchParams.get("type");

  // Breadcrumb items for navigation
  const breadcrumbItems = [
    { label: "Insurance", href: "/policies" },
    {
      label: "Insurance Detail",
      href: `/policy-details?policyNo=${policyId}&type=${policyType}`,
    },
  ];

  // Fetching policy data, policy documents, and bill documents
  let policyData;
  let policyDocData: IFetchPolicyDocDetail[] | undefined = [];
  let billDocData: IFetchPolicyDocDetail[] | undefined = [];

  const [pathName, setPathName] = useState("");

  const { data: policyServeDetail, refetch: refetchPolicyServe } =
    FetchPolicyServeSyncAPI(pathName, false);

  const [pageNum, setPageNum] = useState(1);
  const [policyDataStore, setPolicyDataStore] = useState([]);
  let totalPageSize;

  if (policyId && policyType) {
    const { data: policyDetail } =
      FetchPolicyDetailAPI(policyId, policyType) ?? {};
    const { data: policyDocDetail } =
      FetchPolicyDocDetailAPI(policyId, policyType, docsEnums[policyType], pageNum, 4) ?? {}
    policyData = policyDetail?.data;
    totalPageSize = policyDocDetail?.totalPages;
    policyDocData = policyDocDetail?.data;
  }

  useEffect(() => {
    if (!policyDocData) return;
    setPolicyDataStore((prev) => [...prev, ...policyDocData]);
  }, [policyDocData]);

  // Destructuring policy details for display
  const {
    policyNo,
    insuredName,
    premiumAmount,
    effectiveDate,
    expirationDate,
    user,
    vechiles,
    type,
    buildings,
    coverages,
  } = policyData || {};

  console.log(policyData, "policyData", coverages);

  // Utility functions for date formatting
  const formatDate = (dateStr: string) =>
    new Date(dateStr)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace(/,/, "");

  const formatDOB = (dob: string) => {
    if (!dob) return "";
    const [year, month, day] = dob.split("-");
    return `${month}-${day}-${year}`;
  };

  // Function to handle document download

  useEffect(() => {
    if (pathName) {
      refetchPolicyServe();
    }
  }, [refetchPolicyServe, pathName]);

  const handleDownload = (url: string) => {
    // const encodedUrl = encodeURIComponent(url);

    setPathName(url);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs
            className="mt-4 ml-6"
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbItems.map((item, index) => (
              <React.Fragment key={item.href}>
                {index === breadcrumbItems.length - 1 ? (
                  <Link
                    href={item.href}
                    className="lastLink"
                    style={{
                      outline: "none",
                      color: "#BB3330",
                      fontWeight: "600",
                      textDecorationColor: "#BB3330",
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    href={item.href}
                    style={{
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }}
                  >
                    {item.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Breadcrumbs>
        </Grid>
        <Grid container className="flex m-8 ">
          <Grid
            container
            item
            xs={12}
            className="bg-[#F5F5F5] h-[232px] rounded-8"
            direction="column"
          >
            <Grid container item xs={12} sm={2} className="flex pl-6 pt-4">
              <Grid item md={9} className="flex">
                <Typography>
                  {policyType === "homePolicy" && (
                    <img src={HomeInsuranceIcon} alt="Home Insurance" />
                  )}
                  {policyType === "farmPolicy" && (
                    <img src={FarmInsuranceIcon} alt="Farm Insurance" />
                  )}
                  {policyType === "umbrellaPolicy" && (
                    <img src={UmbrellaInsuranceIcon} alt="Farm Insurance" />
                  )}
                  {policyType === "autoPolicy" && (
                    <img src={InsuranceIcon} alt="Insurance Icon" />
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondaryInfo"
                  className="font-semibold text-sm mt-2"
                >
                  {policyNo}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={4} className="flex pl-6 pt-4">
              <Grid item md={12} className="flex" direction="column">
                <Typography
                  variant="subtitle1"
                  color="#262626"
                  className="font-semibold !text-[24px] leading-[36px]"
                >
                  {insuredName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#545454"
                  className="font-normal text-xs leading-[20px]"
                >
                  Policy Holder
                </Typography>
              </Grid>
            </Grid>

            <Grid container item className="w-full flex gap-10 pl-6 pt-4 ">
              <div className="flex flex-col gap-3">
                <Typography fontSize={12} fontWeight={400} color={"gray.700"}>
                  Premium
                </Typography>
                <Typography>{`$${premiumAmount ?? ""}`}</Typography>
              </div>

              <div className="flex flex-col gap-3">
                <Typography fontSize={12} fontWeight={400} color={"gray.700"}>
                  Effective Date
                </Typography>
                <Typography>{formatDate(effectiveDate ?? "")}</Typography>
              </div>

              <div className="flex flex-col gap-3">
                <Typography fontSize={12} fontWeight={400} color={"gray.700"}>
                  Expiration Date
                </Typography>
                <Typography>{formatDate(expirationDate ?? "")}</Typography>
              </div>
            </Grid>
          </Grid>
          {type !== "autoPolicy" && (
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="mt-[12px]"
            >
              {buildings?.map((building: any, index: number) => (
                <Grid item xs={12} key={building.buildingNo}>
                  <Accordion
                    style={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}
                    // defaultExpanded={index === 0}
                  >
                    <AccordionSummary
                      expandIcon={<img src={ExpandMoreIcon} alt="Expand" />}
                      aria-controls={`panel-content-${index}`}
                      id={`panel-header-${building.buildingNo}`}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <Typography className="font-medium text-base leading-7 pb-3">
                            Policy Details: {policyNo}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            className="text-[14px] leading-[20px] pb-[5px]"
                            color="#333333"
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="#333333"
                            className="text-[14px] leading-[20px] pb-[6px]"
                          >
                            {building.address_line_1} {building.address_line_2}{" "}
                            {building.city} {building.state} {building.zipCode}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="#333333"
                            className="text-[14px] leading-[20px] pb-[6px]"
                          >
                            Residence Premises - Building #{building.buildingNo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        {building?.buildingCoverages?.map((coverage: any) => (
                          <Grid item xs={12} key={coverage.id}>
                            <Typography
                              variant="body2"
                              fontWeight={600}
                              className="text-[14px] leading-[20px] pb-[5px]"
                              color="#333333"
                            >
                              {coverage.coverageName}
                            </Typography>
                            {coverage?.limits?.value &&
                              Object.entries(coverage.limits.value).map(
                                ([key, value]) => (
                                  <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    color="#333333"
                                    className="text-[14px] leading-[20px] pb-[6px]"
                                    key={key}
                                  >
                                    {key}: {value as string}
                                  </Typography>
                                )
                              )}
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          )}
          {type === "autoPolicy" && (
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="mt-[12px]"
            >
              {vechiles?.map((vehicle: any, index: number) => (
                <Grid item xs={12} key={vehicle.vin}>
                  <Accordion
                    style={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}
                    defaultExpanded={index === 0}
                  >
                    <AccordionSummary
                      expandIcon={<img src={ExpandMoreIcon} alt="Expand" />}
                      aria-controls={`panel-content-${vehicle.vin}`}
                      id={`panel-header-${vehicle.id}`}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <Typography className="font-medium text-base leading-7">
                            {vehicle.make} {vehicle.model}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            color="secondary"
                            size="large"
                            className="items-center justify-center flex h-8 w-[101px] bg-[#FFFFFF] hover:bg-[#FFFFFF] border border-[#545454] rounded-[6px]"
                            onClick={() => openPolicyCardDialog(vehicle)}
                          >
                            <span className="text-xs">ID Card</span>
                          </Button>
                        </Grid>
                        <Grid item xs={12} className="mt-1.5">
                          <Typography className="font-medium text-[14px] leading-[20px]">
                            Vin/Serial #{" "}
                            <span
                              className="font-medium text-sm leading-5"
                              style={{ color: "#545454" }}
                            >
                              {vehicle.vin}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          overflowX: "auto",
                          maxWidth: "100%",
                          gap: "5px",
                        }}
                      >
                        {vehicle?.vechileCoverages?.map(
                          (coverage: any, index: number) => (
                            <Grid
                              item
                              xs={12}
                              key={coverage.id}
                              style={{ flexShrink: 0 }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                color="#333333"
                                className="text-[14px] leading-[20px] pb-[5px]"
                              >
                                {coverage.coverageName}
                              </Typography>
                              {coverage?.limits?.value &&
                                Object.entries(coverage.limits.value).map(
                                  ([key, value]) => (
                                    <Typography
                                      variant="body2"
                                      fontWeight={500}
                                      color="#333333"
                                      className="text-[14px] leading-[20px] pb-[6px]"
                                      key={key}
                                    >
                                      {key}: {value as string}
                                    </Typography>
                                  )
                                )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          )}

          {type === "autoPolicy" && (
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="w-[907px] mt-[12px]"
            >
              <Grid item xs={12}>
                <Accordion
                  style={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<img src={ExpandMoreIcon} alt="Expand" />}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#545454"
                      className="font-semibold text-base"
                    >
                      Drivers
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {policyData?.drivers?.map(
                        (driver: any, index: number) => (
                          <Card
                            className="w-[286.33px] h-[96px] ml-[5px] mt-2"
                            key={driver.vechileId}
                          >
                            <Grid container className="m-4">
                              <Grid item xs={12} sm={7}>
                                <Typography
                                  color="#333333"
                                  className="font-inter text-[14px] font-semibold leading-[20px] tracking-normal text-left"
                                >
                                  {driver.firstName} {driver.lastName}
                                </Typography>
                                <Typography
                                  color="#545454"
                                  className="font-inter text-[12px] font-normal leading-[20px] tracking-normal text-left"
                                >
                                  Date of birth: {formatDOB(driver.dob)}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <Typography
                                  color="#757575"
                                  className="font-inter text-[10px] font-normal leading-[14px] tracking-normal text-right mr-6"
                                >
                                  License: {driver.licenseNo}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          )}

          {type === "umbrellaPolicy" && (
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="mt-[12px]"
            >
              <Grid item xs={12}>
                <Accordion
                  style={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<img src={ExpandMoreIcon} alt="Expand" />}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#545454"
                      className="font-semibold text-base"
                    >
                      Personal Umbrella
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        overflowX: "auto",
                        maxWidth: "100%",
                        gap: "5px",
                      }}
                    >
                      {coverages
                        ?.filter(
                          (coverage: any) =>
                            coverage?.limits?.value?.Quantity !== "0"
                        )
                        .map((coverage: any, index: number) => (
                          <Grid
                            item
                            xs={12}
                            style={{ flexShrink: 0 }}
                            key={coverage.id}
                          >
                            <Typography
                              variant="body2"
                              fontWeight={600}
                              color="#333333"
                              className="text-[14px] leading-[20px] pb-[5px]"
                            >
                              {coverage.coverageName}
                            </Typography>
                            {coverage?.limits?.value &&
                              Object.entries(coverage.limits.value).map(
                                ([key, value]) => (
                                  <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    color="#333333"
                                    className="text-[14px] leading-[20px] pb-[6px]"
                                    key={key}
                                  >
                                    {key}: {value as string}
                                  </Typography>
                                )
                              )}
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          )}

          {policyDataStore?.length ? (
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="w-[907px] mt-[12px]"
            >
              <Grid item xs={12}>
                <Grid className="flex  justify-between items-center py-4">
                  <Grid container direction="row" className="p-2">
                    <Typography
                      variant="subtitle1"
                      color="#545454"
                      className="font-semibold text-base"
                    >
                      Policy Documents
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#F5F5F5",
                        borderRadius: "4px",
                      }}
                      className="h-[24px] w-[23px] flex justify-center mb-1 ml-2"
                    >
                      <Typography
                        color="#545454"
                        className="flex flex-row items-center font-semibold text-xs leading-4"
                      >
                        {policyDataStore?.length}
                      </Typography>
                    </div>
                  </Grid>
                  {totalPageSize !== pageNum && (
                    <Button
                      className="w-48 bg-black text-white hover:bg-slate-600"
                      onClick={() => setPageNum((prev) => prev + 1)}
                    >
                      Load More
                    </Button>
                  )}
                </Grid>

                <Grid container spacing={2}>
                  {policyDataStore?.map(
                    ({ documentId, url, uploadedDate, documentName,policyNo, documentType }) => (
                      <Grid item xs={12} sm={6} md={4} key={documentId}>
                        <Card className=" p-6 shadow-[rgba(15,15,15,0.08)_0px_4px_12px_0px]">
                          <div className="flex items-center justify-between">
                            <div className="flex-1">
                              <Tooltip title={"Policy"}>
                                <Typography
                                  color="#333333"
                                  className="font-semibold text-base leading-5 overflow-hidden whitespace-nowrap overflow-ellipsis"
                                  style={{ maxWidth: "135px" }}
                                >
                                  {documentName}
                                </Typography>
                              </Tooltip>
                            </div>
                            <Button
                              className="ml-2 w-[100px] h-[32px] bg-white border border-gray-700 !shadow-[rgba(15,15,15,0.06)_0px_2px_8px_0px] rounded-md"
                              variant="outlined"
                              color="primary"
                              onClick={() => handleDownload(`${url}?policyNumber=${policyNo}&documentType=${documentType}`)}
                            >
                              <img
                                src={ArrowDropDownIcon}
                                alt=""
                                style={{ paddingRight: "5px" }}
                              />
                              <span className="font-normal text-xs leading-4">
                                Download
                              </span>
                            </Button>
                          </div>
                          <Typography
                            color="#757575"
                            className="font-normal text-xs leading-5"
                          >
                            Uploaded: {formatDate(uploadedDate)}
                          </Typography>
                        </Card>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {/* {billDocData?.length && (
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="w-[907px] mt-[12px]"
            >
              <Grid item xs={12}>
                <Grid container direction="row" className="p-2">
                  <Typography
                    variant="subtitle1"
                    color="#545454"
                    className="font-semibold text-base"
                  >
                    Billing Documents
                  </Typography>
                  <div
                    style={{ backgroundColor: "#F5F5F5", borderRadius: "4px" }}
                    className="h-[24px] w-[23px] flex justify-center mb-1 ml-2"
                  >
                    <Typography
                      color="#545454"
                      className="flex flex-row items-center font-semibold text-xs leading-4"
                    >
                      {billDocData.length}
                    </Typography>
                  </div>
                </Grid>

                <Grid container spacing={2}>
                  {billDocData.map(({ documentId, url, uploadedDate }) => (
                    <Grid item xs={12} sm={6} md={4} key={documentId}>
                      <Card className="p-6 shadow-[rgba(15,15,15,0.08)_0px_4px_12px_0px]">
                        <div className="flex items-center justify-between">
                          <div className="flex-1">
                            <Tooltip title={documentId}>
                              <Typography
                                color="#333333"
                                className="font-semibold text-base leading-5 overflow-hidden whitespace-nowrap overflow-ellipsis"
                                style={{ maxWidth: "135px" }}
                              >
                                Billing
                              </Typography>
                            </Tooltip>
                          </div>
                          <Button
                            className="ml-2 w-[100px] h-[32px] bg-white border border-gray-700 !shadow-[rgba(15,15,15,0.06)_0px_2px_8px_0px] rounded-md"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleDownload(url)}
                          >
                            <img
                              src={ArrowDropDownIcon}
                              alt=""
                              style={{ paddingRight: "5px" }}
                            />
                            <span className="font-normal text-xs leading-4">
                              Download
                            </span>
                          </Button>
                        </div>
                        <Typography
                          color="#757575"
                          className="font-normal text-xs leading-5 "
                        >
                          Uploaded: {formatDate(uploadedDate)}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )} */}
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            className="w-[907px] mt-[12px] p-4"
          ></Grid>
        </Grid>
      </Grid>
      <PolicyCard
        open={open}
        handleClose={handleClose}
        policyNumber={policyData?.policyNo ?? ""}
        policyType={policyData?.type ?? ""}
        vehicle={vehicle}
      />
    </>
  );
};

export default PolicyDetails;
