// @ts-nocheck

import React, { useEffect, useMemo, useState } from "react";

import {
  Checkbox,
  Autocomplete,
  TextField,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import ShowBreadCrumb from "./BreadCrumb";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValidationSchemas } from "constants/FormValidationSchemas";

import * as yup from "yup";
import InputItem from "pages/Claims/Collision/InputItem";
import BackAndSaveButton from "pages/Claims/Collision/BackAndSaveButton";
import { useLocation, useNavigate } from "react-router-dom";
import SelectDropDownItem from "pages/Claims/Collision/SelectDropDownItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  FetchCountryDetailAPI,
  FetchPolicyAddressAPI,
  FetchStateDetailAPI,
} from "queries/ClaimQueries";
import {
  useAddCardDetailsAPI,
  useMakePaymentAPI,
  useUpdateCardDetailsAPI,
} from "../queries";
import getCardTypeFromNumber from "../utils/getCardTypeFromNumber";
import getNextMonthStartDate from "../utils/getNextMonthStartDate";
import useQueryParams from "utils/useQueryParams";
import { CardInfo } from "../types";
import getPrevMonthAndYear from "../utils/getPrevMonthAndYear";

import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

import { FetchUserDetailsAPI } from "queries/AuthQueries";
import {
  FetchBillsAPI,
  FetchNextBillAPI,
  useMakePaymentWithFFundingToken,
  useSaveFundingAccountCard,
} from "queries/BillsQueries";
import { red } from "@mui/material/colors";
import HandleErrorResponse from "helper/HandleErrorResponse";
import { useQueryClient } from "react-query";

const inputFields1 = [
  {
    label: "Card Number",
    inputName: "cardNumber",
    xs: 12,
    isMandatory: true,
    placeholder: "Enter Card Number",
    format: "####-####-####-####",
    maxCharacters: 19,
    isValueAndOnChangeFn: true,
  },
  {
    label: "Expiry Date",
    inputName: "expiryDate",
    xs: 6,
    isMandatory: true,
    placeholder: "MM/YY",
    format: "MM/YY",
    maxCharacters: 5,
    isValueAndOnChangeFn: true,
  },
  {
    label: "CVV",
    inputName: "cvv",
    xs: 6,
    isMandatory: true,
    placeholder: "***",
    maxCharacters: 4,
  },
];

const inputFields2 = [
  {
    label: "Name",
    inputName: "name",
    xs: 12,
    isMandatory: true,
    placeholder: "Enter Name",
  },
  {
    label: "Email",
    inputName: "email",
    xs: 12,
    isMandatory: true,
    placeholder: "Enter Email",
  },
  {
    label: "Address Line 1",
    inputName: "addressLine1",
    xs: 12,
    isMandatory: true,
    placeholder: "Enter Address Line 1",
  },
  {
    label: "Zip Code",
    inputName: "zipCode",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter Zip Code",
    // maxCharacters: 5,
    isValueAndOnChangeFn: true,
  },
  {
    label: "City",
    inputName: "city",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter City",
  },
];

// Form validation schema using Yup
const {
  // Destructuring necessary validation schemas
  full_name,
  address_1,
  expiryDate,
  cvv,
  email,
  city,
  zip_code: zipCode,
} = FormValidationSchemas;

const schema = yup.object().shape({
  // Validation schema setup
  name: full_name,
  email,
  addressLine1: address_1,
  zipCode,
  city,
  state: yup
    .string()
    .test("conditional-validation", "State is required", function (value) {
      if (!value || value === "-- Select --") {
        return false;
      }
      return true;
    }),
  cardNumber: yup
  .string()
  .matches(
    /^[0-9*]{4} [0-9*]{4} [0-9*]{4} [0-9*]{3,4}$/,
    "Must be a 15 or 16-digit"
  ),
  expiryDate,
  cvv,
  singleUse: yup
    .boolean()
    .required("Please specify you want to save card or not"),
});

const AddCardForm = ({ breadcrumbItems }: any) => {
  const queryClient = useQueryClient();
  // Hook initializations and state declarations
  const navigate = useNavigate();
  const location = useLocation();
  const [cardValue, setCardValue] = useState("");
  const [cardWithoutAstrik, setCardWithoutAstrik] = useState("");
  const [cardExpDate, setCardExpDate] = useState("");
  // const [isPrimary, setIsPrimary] = useState(false);
  // const [saveCard, setSaveCard] = useState(false);
  const [invalidCardErr, setInvalidCardErr] = useState<
    FieldErrors<{
      cardNumber?: string | undefined;
    }>
  >({
    cardNumber: "",
  });

  const [zipCodeState, setZipCodeState] = useState("");

  // Fetch API calls for state, country, and card details
  const { data: state } = FetchStateDetailAPI();
  // const { data } = FetchCountryDetailAPI();
  const { mutate: draftData } = useAddCardDetailsAPI();
  const { data: allCardDetails } = {};
  const { mutate: updateData } = useUpdateCardDetailsAPI();
  const { mutate: makePayment } = useMakePaymentAPI();
  const { mutate: saveFundingAccountCard } = useSaveFundingAccountCard();
  const { mutate: makePaymentWithFFundingToken } = useMakePaymentWithFFundingToken();
  const { data: receivedBills } = FetchBillsAPI(1, 50, "ASC", false);

  const { userEnteredAmount } = location.state || {};

  const getInvoiceId = () => {
    if (getBillFromLocation) {
      return billInvoiceId;
    }
    return invoiceId || receivedBills?.data?.[0]?.invoiceId || "invoice id";
  };
  const getPolicyNumber = () => {
    if (getBillFromLocation) {
      return billPolicyNo;
    }
    return policyNo || receivedBills?.data?.[0]?.policyNo || "policy No";
  };
  const getAmountPaid = () => {
    if (getBillFromLocation) {
      return billAmountDue;
    }
    return amountDue || Number(userEnteredAmount) || "amount";
  };

  const { data: policyAddressesDetail } = FetchPolicyAddressAPI();
  const meData = queryClient.getQueryData("GET_LOGGED_IN_USER_DETAILS")?.data;

  const policyAddressesData = useMemo(() => {
    return policyAddressesDetail?.data.map((item) => {
      const addrKeys = [
        "address_line_1",
        "address_line_2",
        "city",
        // "state",
        "zipCode",
      ];
      let addrStr = "";
      for (let key in item) {
        if (item[key] && addrKeys.includes(key)) {
          addrStr += `${item[key]} `;
        }
      }
      return { value: item, label: addrStr.trim() };
    });
  }, [policyAddressesDetail?.data]);

  const { data: bill } = FetchNextBillAPI();
  const { amountDue, policyNo, invoiceId } = bill?.data || {};
  const {
    getBillFromLocation,
    invoiceId: billInvoiceId,
    policyNo: billPolicyNo,
    amountDue: billAmountDue,
  } = location.state?.billData || {};

  const { id: cardId } = useQueryParams(["id"]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      name: "",
      email: "",
      addressLine1: "",
      city: "",
      zipCode: "",
      state: "ID",
      singleUse: false,
      // country: "-- Select --",
    },
  });

  const [addressLine1] = watch(["addressLine1"]);

  // Navigation and formatting functions
  const navigateToPrevScreen = () => {
    navigate("/select-payment-method");
  };

  const formatCardNumber = (value: string) => {
    // Function to format the card number
    const cleanedValue = value.replace(/[^\d*]/g, "").substring(0, 16);
    const formattedValue = cleanedValue.replace(/(.{4})/g, "$1 ");

    return formattedValue.trim();
  };

  const formatCardExpDate = (value: string) => {
    // Function to format the card expiry date
    const cleanedValue = value.replace(/\D/g, "");
    const formattedValue = cleanedValue.substring(0, 4);
    let result = formattedValue;
    if (
      (cleanedValue.length === 2 && !cardExpDate.endsWith("/")) ||
      cleanedValue.length > 2
    ) {
      result = formattedValue.replace(/^(.{2})/, "$1/");
    }
    return result;
  };

  // ... Other utility functions

  const { data: userDetails, dataUpdatedAt } = FetchUserDetailsAPI();

  const { userData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        userData: userDetails?.data,
      };
    } else {
      return {
        userData: {},
      };
    }
  }, [dataUpdatedAt]);

  const getInputValues = (input: string): string | undefined => {
    if (input === "cardNumber") {
      return cardValue;
    } else if (input === "expiryDate") {
      return cardExpDate;
    }
    return undefined;
  };

  const handleFormattingOnValueChange = (input: string, value: string) => {
    if (input === "cardNumber") {
      setCardValue(formatCardNumber(value));
    } else if (input === "expiryDate") {
      setCardExpDate(formatCardExpDate(value));
    }
  };

  const formatDate = (yy_mm) => {
    // Assuming yy_mm is a string in the format "yy/mm"
    const [month, year] = yy_mm.split("/").map(Number);

    // Adding the century to the year (assuming 20th century for simplicity)
    const fullYear = year < 50 ? 2000 + year : 1900 + year;

    // Formatting the date as "YYYY-MM"
    const formattedDate = `${fullYear}-${String(month).padStart(2, "0")}`;

    return formattedDate;
  };

  function replaceAsterisks(withAstrik, withoutAstrik) {
    if (withAstrik.length !== withoutAstrik.length) {
      // The lengths should be the same for replacement to make sense
      return withAstrik;
    }

    let result = "";
    for (let i = 0; i < withAstrik.length; i++) {
      if (withAstrik[i] === "*") {
        result += withoutAstrik[i];
      } else {
        result += withAstrik[i];
      }
    }

    return result;
  }

  const handleSaveCard = async () => {
    // Function to handle saving the card details

    const {
      cardNumber = "",
      cvv,
      expiryDate,
      addressLine1,
      city,
      state,
      zipCode,
      // country,
      singleUse,
      name,
    } = getValues();
    const cardNum = cardNumber.split(" ").join("");
    const cardType = getCardTypeFromNumber(cardNum).name;

    let finalCardNum = cardNum;

    const saveFundingAccountCardPayload = {
      billingAddress: {
        regionCode: state,
        postalCode: zipCode,
        countryCode: "US",
      },
      owner: {
        kind: "BillerAccountOwner",
        billerAccountId: getPolicyNumber(),
        billerId: process.env.REACT_APP_SPEED_PAY_BILLER_ID,
      },
      kind: "Card",
      cardNumber: finalCardNum,
      securityCode: cvv,
      singleUse,
      expirationDate: expiryDate?.split("/").join(""),
      cardHolderName: name,
      paymentMethodKind: "CreditCard",
    };

    saveFundingAccountCard(saveFundingAccountCardPayload, {
      onSuccess: (res) => {
        if (singleUse) {
          makePaymentWithFFundingToken({
            fundingAccountId: res.data.fundingToken,
            amountPaid: userEnteredAmount,
            policyNumber: getPolicyNumber(),
          },{
            onSuccess : (data) => {
              const status = data?.data?.status;

              if (status === "success" || status === "OK") {
                navigate("/payment-success", {
                  state: { responseData: data?.data },
                });
              } else {
                navigate("/payment-denied", {
                  state: { responseData: data?.data },
                });
              }
            },
            onError : (err) => {
              HandleErrorResponse(err);
            }
          })
        } else {
          navigate(-1);
          // redirect to the screen cards listing and make payment from there.
        }
      },
      onError: (err) => {
        HandleErrorResponse(err);
      },
    });
  };

  const handleZipCode = (value) => {
    // Ensure only numeric characters are allowed
    const updatedValue = value.replace(/[^0-9]/g, "");

    // Handle your state or other logic here
    setZipCodeState(updatedValue);
  };

  // Effect hook to set form values if editing an existing card
  useEffect(() => {
    // Logic to populate form fields if editing an existing card
    const keys = [
      "cvv",
      "cardType",
      "addressLine1",
      "city",
      "zipCode",
      // "country",
      "state",
    ];
    if (cardId && allCardDetails && state) {
      const cardData = allCardDetails?.data?.find(
        (item) => item.id === +cardId
      );
      if (!cardData) {
        navigate("/select-method");
        return;
      }
      for (let key of keys) {
        if (
          key === "state" &&
          cardData.state === ""
          // || (key === "country" && cardData.country === "")
        ) {
          setValue(key, "-- Select --");
          continue;
        }
        if (key === "zipCode") {
          setZipCodeState(cardData[key] || "");
        }
        setValue(key as any, cardData[key] || "");
      }
      const cleanedValue = cardData.cardNumber
        .replace(/[^\d*]/g, "")
        .substring(0, 16);
      let asterisks = "*".repeat(Math.min(12, cleanedValue.length));
      const substr = cleanedValue.substring(12);
      asterisks = asterisks.replace(/(.{4})/g, "$1 ");
      const formattedValue = asterisks + substr;
      setCardWithoutAstrik(cardData.cardNumber);

      setValue("cardNumber", formatCardNumber(formattedValue));
      setValue("expiryDate", getPrevMonthAndYear(cardData.expiryDate));

      setCardValue(formatCardNumber(formattedValue));
      setCardExpDate(getPrevMonthAndYear(cardData.expiryDate));
      // setIsPrimary(cardData.isPrimary);
    }
  }, [allCardDetails, cardId, navigate, setValue, state]);

  return (
    <div className="!w-[530px]">
      <Grid item xs={12}>
        <Breadcrumbs
          className="mt-4"
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          {breadcrumbItems.map((item, index) => (
            <Link
              id={index}
              key={index}
              to={item.href}
              className={index === breadcrumbItems.length - 1 ? "lastLink" : ""}
              style={
                index === breadcrumbItems.length - 1
                  ? { outline: "none", color: "#BB3330", fontWeight: "600" }
                  : {
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }
              }
            >
              {item.label}
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} className="mb-4">
        <Typography className="font-semibold text-lg leading-7 mt-5">
          {cardId ? "Update Card" : "Make Payment"}
        </Typography>
      </Grid>

      <Container className="-ml-5">
        <form onSubmit={handleSubmit(handleSaveCard)} className="space-y-2">
          <Grid container spacing={3}>
            {inputFields1.map((item) => {
              return (
                <InputItem
                  {...item}
                  control={control}
                  errors={errors}
                  isValueAndOnChangeFn
                  value={
                    item?.isValueAndOnChangeFn
                      ? getInputValues(item.inputName)
                      : undefined
                  }
                  InputProps={
                    item.inputName === "cardNumber"
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              {
                                getCardTypeFromNumber(
                                  cardValue.split(" ").join("")
                                ).img
                              }
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  onValueChange={
                    item?.isValueAndOnChangeFn
                      ? (value) =>
                          handleFormattingOnValueChange(item.inputName, value)
                      : undefined
                  }
                  textFieldProps={{ inputFormat: item.format || "" }}
                />
              );
            })}
          </Grid>
          <Grid>
            <Typography fontSize={14} fontWeight={500} marginTop={2}>
              Billing Information
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {inputFields2.map(
              ({
                label,
                inputName,
                xs,
                isMandatory,
                placeholder,
                maxCharacters,
                isValueAndOnChangeFn,
              }) => {
                return label === "Address Line 1" ? (
                  <Grid item xs={12}>
                    <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                      Address Line 1<span className="text-error-500">*</span>
                    </Typography>
                    <Controller
                      name="addressLine1"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Autocomplete
                          options={policyAddressesData || []}
                          getOptionLabel={(option) => option.label}
                          freeSolo
                          inputValue={addressLine1 || ""}
                          onChange={(event, newInputValue = {}) => {
                            const matchingOption = policyAddressesData.find(
                              (option) => option.label === newInputValue
                            );
                            const selectedValue =
                              newInputValue?.value ||
                              matchingOption?.value ||
                              {};

                            const keyValue = {
                              addressLine1: "address_line_1",
                              addressLine2: "address_line_2",
                              zipCode: "zipCode",
                              city: "city",
                              // state: "state",
                            };

                            for (let key in keyValue) {
                              if (keyValue[key]) {
                                let value = selectedValue[keyValue[key]];
                                if (key === "zipCode") {
                                  value =
                                    selectedValue[keyValue[key]]?.split("-")[0];
                                  setZipCodeState(value);
                                }
                                setValue(key, value);
                              }
                            }
                            if (!getValues("name")) {
                              setValue("name", meData?.insuredName);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              placeholder="Enter Address Line 1"
                              fullWidth
                              {...field}
                              type="string"
                              error={!!errors?.addressLine1}
                              helperText={errors?.addressLine1?.message || ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <InputItem
                    label={label}
                    placeholder={placeholder}
                    control={control}
                    inputName={inputName}
                    errors={errors}
                    xs={xs}
                    {...(isValueAndOnChangeFn
                      ? {
                          isValueAndOnChangeFn: true,
                          value: zipCodeState,
                          onValueChange: (value) => handleZipCode(value),
                        }
                      : {})}
                    isMandatory={isMandatory}
                    // maxCharacters={maxCharacters}
                  />
                );
              }
            )}
          </Grid>

          <SelectDropDownItem
            label="State"
            control={control}
            name="state"
            errors={errors}
            data={state?.data}
          />

          {location.pathname.includes("/add-card") && (
            <FormGroup>
              <Controller
                name="singleUse"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        color="primary"
                      />
                    }
                    label="Pay instantly without saving your bank details."
                  />
                )}
              />
            </FormGroup>
          )}

          <BackAndSaveButton
            // isValid={isValid && (!cardId && isPrimary ? saveCard : true)}
            isValid={isValid}
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleSaveCard)}
            saveAndNextText={cardId ? "Update Card" : "Proceed"}
          />
        </form>
      </Container>
    </div>
  );
};

export default AddCardForm;
