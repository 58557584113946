import React from 'react';
import PaymentDetail from './PaymentDetail';
import paymentDenied from "assets/Images/paymentDenied.svg";
import { useMakePaymentAPI } from '../queries';  // Unused import, can be removed for cleaner code.
import { useLocation } from 'react-router-dom';
import formatCreditCardNumber from '../utils/formatCreditCardNumber';

// PaymentDenied Functional Component
const PaymentDenied = () => {
  // useLocation hook to access the state passed from the previous route
  const location = useLocation();
  const { amount, txnId, insuredName, paymentDate, policyNumber ,meta,baseAmount,serviceFee} =
    location.state?.responseData || {}; // Destructuring the payment response data
 
  // Rendering the PaymentDetail component with necessary props
  return (
    <div>
      <PaymentDetail
        icon={paymentDenied} // Icon to display for the denied payment
        status={"Payment Denied"} // Status message
        amount={amount || ""} // Payment amount (or empty string if not available)
        quote={meta?.default || "We're sorry, but your payment was unsuccessful. Please review the information and try again."} // Message to the user
        // transcID={txnId || ""}  Transaction ID (or empty string if not available)
        insuredName={insuredName || ""}
        paymentDate={paymentDate || ""}
        baseAmount={baseAmount || ""}
        serviceFee={serviceFee || ""}
      />
    </div>
  )
}

export default PaymentDenied;
