//@ts-nocheck

// Importing necessary components and hooks from Material-UI, React, and other libraries.
import {
  Breadcrumbs,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import SearchIcon from "assets/Icons/search-md.svg";
import ReportGlassIcon from "assets/Icons/reportGlass.svg";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CommonDialog from "components/Common/CommonDialog";
import car from "assets/Images/carimage.png";
import Accident from "assets/Images/accident.png";
import CarPin from "assets/Images/carPin.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  FetchClaimsDetailAPI,
  FetchClaimsDraftAPI,
  FetchClaimsDraftDetailAPI,
  useDeleteClaimsDraft,
} from "queries/ClaimQueries";
import { ClaimSteps, RedirectFromStep } from "../constants";
import { ClaimTypeEnums } from "../types";
import { CheckUserPolicyExistsAPI, FetchPolicyServeSyncAPI } from "queries/PolicyQuery";

// Utility functions to determine the color based on claim status.
const getStatusColor = (status: string) => {
  if (status === "SUCCESSFUL") {
    return "#07794C";
  } else if (status === "IN PROCESS") {
    return "#997400";
  } else if (status === "REJECTED") {
    return "#FECACA";
  } else {
    return "";
  }
};

const getStatusBackgroundColor = (status: string) => {
  if (status === "SUCCESSFUL") {
    return "#EDF7F2";
  } else if (status === "IN PROCESS") {
    return "#FFF3CC";
  } else if (status === "REJECTED") {
    return "#FECACA";
  } else {
    return "";
  }
};

// Breadcrumb configuration for navigation.
const breadcrumbItems = [
  { label: "Home", href: "/dashboard" },
  { label: "Claims", href: "/claims" },
];

// Type definition for selected claim data.
type ISelectedClaimData = {
  policyId: number;
  claimId: string;
  claimType: string;
  policyNo: string;
};

// Initial state for the selected claim.
const initSelectedClaimData = {
  policyId: 0,
  claimId: "",
  claimType: "",
  policyNo: "",
};

// Main component for handling claims.
const Claims = () => {
  // State hooks for handling selected claim and dialog state.
  const [selectedClaimData, setSelectedClaimData] =
    useState<ISelectedClaimData>(initSelectedClaimData);
  const [openDialog, setOpenDialog] = useState(false);

  // Navigation hook from react-router-dom for programmatic navigation.
  const navigate = useNavigate();

  // Function to navigate to report claim page.
  const navigateToReportClaim = (id: any) => {
    navigate(`/report-claim`);
  };

  // API queries for fetching claim data.
  const { data: claimsDraftData } = FetchClaimsDraftDetailAPI();
  const { data: claimsData } = FetchClaimsDetailAPI();
  const deleteClaimsDraftMutation = useDeleteClaimsDraft();

  // Fetching draft claim detail.
  const { data: claimsDraftDetail, refetch: refetchClaimsDraft } =
    FetchClaimsDraftAPI(selectedClaimData.claimId, false);

  const {data : userPolicyDetails } : any = CheckUserPolicyExistsAPI()

  // Function to handle deletion of a claim draft.
  const handleDelete = (claimId: string) => {
    try {
      // Trigger the deleteClaimsDraftMutation
      deleteClaimsDraftMutation.mutate(claimId);
    } catch (error) {
      console.error("Error deleting claims draft:", error);
    }
  };

  // Effect hook to refetch claim draft details when selected claim ID changes.
  useEffect(() => {
    if (selectedClaimData.claimId) {
      console.log("selectedClaimData.claimId", selectedClaimData.claimId);
      refetchClaimsDraft();
    }
  }, [refetchClaimsDraft, selectedClaimData.claimId]);

  // Callback to redirect to the last left page in the claim process.
  const redirectToLastLeftPage = useCallback(
    (policyId: number, claimId: string, claimType = "", policyNo) => {
      setSelectedClaimData({ policyId, claimId, claimType, policyNo });
    },
    []
  );

  // Effect hook to handle navigation based on claim type and draft details.
  useEffect(() => {
    const {
      policyId,
      claimId,
      claimType: claimTypeText,
      policyNo,
    } = selectedClaimData;

    if (!policyId || !claimId) return;

    if (!claimTypeText) {
      navigate(
        `/select-claim?policyId=${policyId}&claimId=${claimId}&policyNo=${policyNo}`
      );
      return;
    }

    const claimType = claimTypeText.toLowerCase().split(" ").join("_");
    const step = claimsDraftDetail?.data?.step;
    if (
      step &&
      (ClaimTypeEnums.collision_and_rollover === claimType ||
        ClaimTypeEnums.theft === claimType ||
        ClaimTypeEnums.impact_with_an_animal === claimType)
    ) {

      // step by step routing removed
      // const route = RedirectFromStep[claimType as ClaimTypeEnums][step + 1];
      navigate(
        `/collision-details?policyId=${policyId}&claimId=${claimId}&claimType=${claimType
          .toLowerCase()
          .split(" ")
          .join("_")}&policyNo=${policyNo}`
      );
    }
  }, [claimsDraftDetail?.data?.step, navigate, selectedClaimData]);

  // Function to open the auto claim dialog.
  const openAsutoClaimDialog = () => {
    setOpenDialog(true);
  };

  // Callback for closing the dialog.
  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  // Callback to navigate to the success page.
  const openSuccessPage = useCallback(() => {
    navigate("/other-claim-success");
  }, [navigate]);

  return (
    <div className="ml-4">
      <Grid
        container
        spacing={2}
        className="  items-center justify-center mt-1 ml-[-2px]"
      >
        <Grid item xs={12}>
          <Breadcrumbs
            className="mt-4 ml-6"
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbItems.map((item, index) => (
              <React.Fragment key={item.label}>
                {index === breadcrumbItems.length - 1 ? (
                  <Link
                    href={item.href}
                    className="lastLink"
                    style={{
                      outline: "none",
                      color: "#BB3330",
                      fontWeight: "600",
                      textDecorationColor: "#bb3330",
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    href={item.href}
                    style={{
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }}
                  >
                    {item.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Breadcrumbs>
        </Grid>
      </Grid>
    { userPolicyDetails && userPolicyDetails?.data?.policyExists === true ?
    
    <Grid container className="w-[486px]">
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="font-semibold text-lg text-center tracking-[0.8px]"
          >
            I want to
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className="w-[235px] h-[126px]">
          <ButtonBase
            className="flex w-full h-full"
            onClick={openAsutoClaimDialog}
          >
            <Card className="w-[235px] h-[126px]">
              <CardContent className="h-full">
                <div className="flex items-center justify-center mb-4">
                  <img src={SearchIcon} alt="" />
                </div>
                <Typography
                  variant="subtitle1"
                  color="#0F0F0F"
                  className="font-medium text-[16px] leading-[28px] text-center"
                >
                  Report an Auto Claim
                </Typography>
              </CardContent>
            </Card>
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm={6} className="w-[235px] h-[126px]">
          <ButtonBase
            className="flex w-[235px] h-[126px]"
            onClick={openSuccessPage}
          >
            <Card className="w-[235px] h-[126px]">
              <CardContent className="h-full">
                <div className="flex items-center justify-center mb-4">
                  <img src={ReportGlassIcon} alt="" />
                </div>
                <Typography
                  variant="subtitle1"
                  color="#0F0F0F"
                  className="font-medium text-[16px] leading-[28px] text-center"
                >
                  Report Glass or Windshield Damage
                </Typography>
              </CardContent>
            </Card>
          </ButtonBase>
        </Grid>
      </Grid>
      :
      <Typography
      variant="subtitle1"
      color="#0F0F0F"
      className="font-semibold text-[14px] leading-[20px] text-center mt-12"
    >
      No policy found!
    </Typography> 
      }
      {(claimsDraftData?.data?.length || 0) > 0 && (
        <Grid container className="w-[487px] mt-4 ml-[-8px]" spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="#0F0F0F"
              className="font-semibold text-[14px] leading-[20px]"
            >
              Continue Filling
            </Typography>
          </Grid>
          {claimsDraftData?.data?.map(
            ({ policyId, policyNo, name, claimId, claimType }) => (
              <Grid item key={claimId} className="p-1 container w-full flex">
                <Card className="flex-1 h-[72px] mt-2">
                  <CardContent>
                    <Grid container>
                      <Grid
                        onClick={() =>
                          redirectToLastLeftPage(
                            policyId,
                            claimId,
                            claimType,
                            policyNo
                          )
                        }
                        item
                        xs={10}
                        sm={10}
                        direction="column"
                      >
                        <Typography
                          variant="subtitle1"
                          color="#333333"
                          className="text-start font-semibold text-[14px] leading-[20px]"
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#757575"
                          className="text-start font-normal text-[10px] leading-[14px] mt-1"
                        >
                          Policy No. {policyNo}
                        </Typography>
                      </Grid>
                      <Grid xs={2} item className="flex justify-end mt-2">
                        <div onClick={() => handleDelete(claimId)}>
                          <CloseIcon />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      )}
      {claimsData?.data?.length > 0 && (
        <Grid
          container
          className="w-[469px] mt-4 px-2 rounded-md bg-[#F5F5F5] ml-[8px]"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="#0F0F0F"
              className="font-semibold text-[14px] leading-[20px]"
            >
              Claims
            </Typography>
          </Grid>
          {claimsData?.data?.map(
            ({
              name,
              policyNo,
              status,
              claimReferenceNo,
              amount,
            }: {
              name: string;
              claimReferenceNo: string;
              policyNo: string;
              status: string;
              amount: any;
            }) => (
              <ButtonBase className="flex w-full" key={policyNo}>
                <Grid item className="p-4 container">
                  <Card className="w-[455] h-[108px] py-1 px-4">
                    <CardContent>
                      <Grid container className="w-full">
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          direction="column"
                          className="w-full"
                        >
                          <div className="flex justify-between items-center w-[370px] ">
                            <div>
                              <Typography
                                variant="subtitle1"
                                color="#333333"
                                className="text-start font-semibold text-[14px] leading-[20px]"
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="#757575"
                                className="text-start font-normal text-[10px] leading-[14px] mt-1"
                              >
                                Policy No. {policyNo}
                              </Typography>
                            </div>
                            {amount && status !== "IN PROCESS" && (
                              <div>
                                <Typography
                                  variant="subtitle1"
                                  color="#757575"
                                  className="text-start font-normal text-[10px] leading-[14px] mt-1"
                                >
                                  Claim Amount
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="#757575"
                                  className="text-end font-normal text-[10px] leading-[14px] mt-1"
                                >
                                  {amount ? "$ " + amount : ""}
                                </Typography>
                              </div>
                            )}
                          </div>
                          <div
                            className="h-[24px] w-fit flex justify-start px-2 items-center mt-3"
                            style={{
                              borderRadius: "4px",
                              color: getStatusColor(status),
                              backgroundColor: getStatusBackgroundColor(status),
                            }}
                          >
                            <Typography
                              variant="body1"
                              className=" whitespace-nowrap font-semibold text-[10px] leading-[14px]"
                            >
                              {status} - {claimReferenceNo}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </ButtonBase>
            )
          )}
        </Grid>
      )}

      <CommonDialog
        open={openDialog}
        onClose={handleClose}
        title="Filing a claim is easy."
        maxWidth="xs"
        actions={
          <Button
            className="w-full font-semibold text-4 font-inter leading-7 text-center bg-[#262626] text-white hover:bg-gray-950 ml-4 mr-4 mb-6"
            onClick={navigateToReportClaim}
          >
            Start filling a claim
          </Button>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className="font-normal text-[12px] leading-[17px] text-left pl-4 pr-4 pt-2">
              To process your claim, kindly provide some details about your
              vehicle. This will help us determine the appropriate amount to be
              claimed.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="gap-x-4 gap-y-4">
          <Grid item xs={12}>
            <ListItem className="pt-5 pb-4">
              <ListItemText
                className="text-[#0F0F0F] Cltxt"
                primary="1. When it happened"
              />
              <img src={car} alt="Car" />
            </ListItem>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="pt-0 pb-4">
              <ListItemText
                className="text-[#0F0F0F] Cltxt"
                primary="2. Provide Details About the Incident"
              />
              <img src={Accident} alt="Accident" />
            </ListItem>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="pt-0 pb-0 mb-[-6px]">
              <ListItemText
                className="text-[#0F0F0F] Cltxt"
                primary="3. Where it happened"
              />
              <img src={CarPin} alt="Car Pin" />
            </ListItem>
          </Grid>
        </Grid>
      </CommonDialog>
    </div>
  );
};

export default Claims;
